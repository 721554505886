export function randId() {
  // + take seed param
  const typedArray = new Uint8Array(4);
  crypto.getRandomValues(typedArray);
  return Array.from(typedArray)
    .map(e => e.toString(16).padStart(2, "0"))
    .join("");
}

export function urlString({ base = window.location, path, params = {} }) {
  const url = new URL(path, String(base));
  for (const [name, value] of Object.entries(params)) {
    url.searchParams.set(name, value);
  }
  return url.toString();
}

export function int32Digest(str) {
  var hash = 0,
    i,
    chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return Math.abs(hash);
}

// ---

export function callAndCatch(asyncFn, errorHandler) {
  return asyncFn().catch(errorHandler);
}

// ---

export function Frag({ children }) {
  return children();
}
