//@ts-check
import * as React from "react";
import { AgoraSDKService } from "~/AgoraSDKService";
import {
  AVChannelService,
  TrackPair,
  ChanSessionKey,
  useAVChannelService,
} from "~/AVChannelService";
import {
  AgoraBackendConfig,
  AgoraConnectConfig,
} from "./AgoraAVChannelService";
import {
  SimpleStunBackendConfig,
  SimpleStunConnectConfig,
} from "./SimpleStunChannelService";
import { AppError, useDispatchToErrorBoundary } from "~/ErrorBoundary";
import * as Utils from "~/Utils";
import * as VideoRenderer from "./VideoFileRenderer";

const sx = (
  /**
   * @type {string}
   */ s,
) => s;

/**
 * @param {{ channelId: string, token: string, backend: "agora" | "simple-stun" }} props
 * @returns {React.ReactElement}
 */
export default function RoundTrip({ channelId, token, backend }) {
  // @ts-ignore
  const appId = import.meta.env.CLIENT_EXPOSED_AGORA_APP_ID;
  if (typeof appId !== "string")
    throw AppError("ConfigError", "CLIENT_EXPOSED_AGORA_APP_ID must be set");

  if (backend === "agora") {
    return (
      <AgoraSDKService>
        {sdkModule =>
          sdkModule ? (
            <AVChannelService
              config={{
                backend: "agora",
                backendConfig: new AgoraBackendConfig({
                  appId,
                  sdkModule,
                }),
              }}
            >
              <RoundTripController
                cid={channelId}
                token={token}
                backend={backend}
              />
            </AVChannelService>
          ) : (
            // showing nothing is better than flashing a loading msg (for <300ms)
            <>{/* loading agora module ... */}</>
          )
        }
      </AgoraSDKService>
    );
  } else {
    return (
      <AVChannelService
        config={{
          backend: "simple-stun",
          backendConfig: new SimpleStunBackendConfig({
            wsURL: `${window.location.protocol === "https:" ? "wss" : "ws"}://${
              window.location.host
            }/ws`,
          }),
        }}
      >
        <RoundTripController cid={channelId} token={token} backend={backend} />
      </AVChannelService>
    );
  }
}

/**
 * @param {Object} props
 * @param {string} props.cid
 * @param {string} props.token
 * @param {"agora" | "simple-stun"} props.backend
 * @returns {React.ReactElement}
 */
function RoundTripController({ cid, token, backend }) {
  const isMountedRef = React.useRef(true);
  const { avcs } = useAVChannelService();
  const dispatchToErrorBoundary = useDispatchToErrorBoundary();
  const [pubChannelToken, setPubChannelToken] = React.useState(null);
  const [subChannelToken, setSubChannelToken] = React.useState(null);
  const [localMediaTrackPair, setLocalMediaTrackPair] = React.useState(
    new TrackPair({}),
  );

  const sourceVideoUrl = "caminandes_llamigos_1080p.webm";

  const pubEid = cid + "-pub";
  const pubCsi = new ChanSessionKey({ cid, eid: pubEid });
  const subCsi = new ChanSessionKey({ cid, eid: cid + "-sub" });

  const pubConnStatus = avcs.connStatus(pubCsi); // channel session connection status
  const pubStatus = avcs.pubStatus(pubCsi); // channel session publish status
  const subConnStatus = avcs.connStatus(subCsi); // channel session connection status
  const subStatus = avcs.subStatus(subCsi, pubEid); // (own) entry subscription status
  const subscribedTrackPair = avcs.getSubTrackPair(subCsi, pubEid);

  React.useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  /**
   * fetch tokens and set if still mounted
   */
  React.useEffect(() => {
    Utils.callAndCatch(async () => {
      const tokens = await getAgoraTokens([pubCsi, subCsi], token);
      if (isMountedRef.current) {
        setPubChannelToken(tokens[0]);
        setSubChannelToken(tokens[1]);
      }
    }, dispatchToErrorBoundary);
    /** cleanup not required as response handler guards against unmount */
  }, []);

  React.useEffect(() => {
    Utils.callAndCatch(async () => {
      if (pubChannelToken === null) return;
      if (pubConnStatus !== "unconnected") return;
      await avcs.connect(
        pubCsi,
        { authToken: backend === "agora" ? pubChannelToken : token },
        backend === "agora"
          ? new AgoraConnectConfig({ codec: "vp8", mode: "rtc", role: null })
          : new SimpleStunConnectConfig({ dummy: "xxx" }),
      );
    }, dispatchToErrorBoundary);
    return () => {
      if (!isMountedRef.current) {
        console.log("#ChannelDisconnect");
        avcs.disconnect(pubCsi);
      }
    };
  });

  React.useEffect(() => {
    Utils.callAndCatch(async () => {
      if (subChannelToken === null) return;
      if (subConnStatus !== "unconnected") return;
      await avcs.connect(
        subCsi,
        { authToken: backend === "agora" ? subChannelToken : token },
        backend === "agora"
          ? new AgoraConnectConfig({ codec: "vp8", mode: "rtc", role: null })
          : new SimpleStunConnectConfig({ dummy: "hey" }),
      );
    }, dispatchToErrorBoundary);
    return () => {
      if (!isMountedRef) {
        console.log("#ChannelDisconnect");
        avcs.disconnect(subCsi);
      }
    };
  });

  React.useEffect(() => {
    Utils.callAndCatch(async () => {
      if (pubConnStatus !== "connected") return;
      if (localMediaTrackPair.count == 0) return;
      if (localMediaTrackPair.count == 1) {
        throw AppError(
          "MissingTrack",
          "expected VideoFileRenderer to emit both video and audio tracks",
        );
      }
      if (pubStatus.audio === "unattached") {
        await avcs.publish(pubCsi, localMediaTrackPair.audio);
      }
    }, dispatchToErrorBoundary);
  });

  React.useEffect(() => {
    Utils.callAndCatch(async () => {
      if (pubConnStatus !== "connected") return;
      if (localMediaTrackPair.count == 0) return;
      if (localMediaTrackPair.count == 1) {
        throw AppError(
          "MissingTrack",
          "expected VideoFileRenderer to emit both video and audio tracks",
        );
      }
      if (pubStatus.video === "unattached") {
        await avcs.publish(pubCsi, localMediaTrackPair.video);
      }
    }, dispatchToErrorBoundary);
  });

  React.useEffect(() => {
    Utils.callAndCatch(async () => {
      if (subStatus.audio === "attachable") {
        await avcs.subscribe(subCsi, pubEid, "audio");
      }
      if (subStatus.video == "attachable") {
        await avcs.subscribe(subCsi, pubEid, "video");
      }
    }, dispatchToErrorBoundary);
  });

  return React.useMemo(() => {
    return (
      <RoundTripPresenter
        sourceVideoUrl={sourceVideoUrl}
        onSourceVideoTracksLoaded={(videoTrack, audioTrack) => {
          setLocalMediaTrackPair(
            new TrackPair({ audio: audioTrack, video: videoTrack }),
          );
        }}
        subscribedTrackPair={subscribedTrackPair}
        subConnStatus={subConnStatus}
        subStatus={subStatus}
      />
    );
  }, [sourceVideoUrl, subscribedTrackPair, subConnStatus, subStatus]);
}

/**
 * @param {Object} props
 * @param {string} props.sourceVideoUrl
 * @param {function(MediaStreamTrack, MediaStreamTrack): void} props.onSourceVideoTracksLoaded
 * @param {TrackPair} props.subscribedTrackPair
 * @param {AVChannelService_.ConnStatus} props.subConnStatus
 * @param {import("~/AVChannelService").SubStatus} props.subStatus
 * @returns {React.ReactElement}
 * @prettierignore
 */
function RoundTripPresenter({
  sourceVideoUrl,
  onSourceVideoTracksLoaded,
  subscribedTrackPair,
  subConnStatus,
  subStatus,
}) {
  return (
    <div className={sx("Root")}>
      {
        <div
          className={sx("Streams")}
          style={{ display: "flex", justifyContent: "space-around" }}
        >
          <div className={sx("LocalStream")}>
            <div className={sx("Header")}>Source video</div>
            <VideoRenderer.FromUrl
              url={sourceVideoUrl}
              onTracksLoaded={onSourceVideoTracksLoaded}
            />
          </div>
          <div className={sx("SubscribedStream")}>
            <div className={sx("Header")}>
              Subscribed channel stream of source video
            </div>
            {renderSubscribedTracks(
              subConnStatus,
              subStatus,
              subscribedTrackPair,
            )}
          </div>
        </div>
      }
    </div>
  );
}

/**
 *
 * @param {AVChannelService_.ConnStatus} connStatus
 * @param {import("~/AVChannelService").SubStatus} subStatus
 * @param {TrackPair} subscribedTrackPair
 * @returns {React.ReactElement}
 */
function renderSubscribedTracks(connStatus, subStatus, subscribedTrackPair) {
  switch (connStatus) {
    case "connected":
      switch (subStatus.combinedTracks) {
        case "unattached":
          return <>Wait for publishing...</>;
        case "attachable":
        case "attaching":
          return <>Subscribing...</>;
        case "attached":
          return (
            <VideoRenderer.FromTracks
              mediaTrackPair={subscribedTrackPair}
              styleStr={"width: 480px; height: 270px;"}
            />
          );
      }
      break; // + no need? but not complaining no return value
    case "connecting":
      return <>Connecting...</>;
    case "unconnected":
      return <>Not connected yet...</>;
    // + didn't warn about "reconnecting"
  }
}

/**
 * @param {ChanSessionKey[]} agoraTokenParams
 * @param {string} token
 * @returns {Promise<string[]>}
 */
async function getAgoraTokens(agoraTokenParams, token) {
  console.log(agoraTokenParams);
  const endpoint = "/api/getAgoraTokens";
  const res = await fetch(Utils.urlString({ path: endpoint }), {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({
      params: agoraTokenParams.map(({ cid, eid }) => ({
        cid,
        eid,
      })),
      token,
    }),
  });
  if (res.status !== 200)
    throw AppError(
      "getAgoraTokensRequestFailed",
      `request to ${endpoint} failed with ${res.status}`,
    );
  const tokens = await res.json();
  return tokens;
}
