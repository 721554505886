//@ts-check
import * as React from "react";
import * as VideoRenderer from "~/VideoRenderer";
import "~/VideoRendererEg.css";
import NSBox from "~/NSBox";

const [box, className] = NSBox("VideoRendererEg");

export default function Playground() {
  const sourceVideoUrl = "caminandes_llamigos_1080p.webm";
  const [trackPair, setTrackPair] = React.useState(
    new VideoRenderer.TrackPair(),
  );
  return box`Root`(
    box`SourceViaUrl`(
      box`Header`("Source <video> yielding tracks"),
      <VideoRenderer.FromUrl
        className={className("Video")}
        url={sourceVideoUrl}
        onTracksLoaded={_trackPair => setTrackPair(_trackPair)}
        volume={0}
        autoPlay
        loop
      />,
    ),
    box`TracksFromSource`(
      box`Header`("dest <video> using tracks from source <video>"),
      <VideoRenderer.FromTracks
        className={className("Video")}
        trackPair={trackPair}
        volume={0}
        muted={true}
      />,
    ),
  );
}
