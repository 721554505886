/**
 * Add new top level components to `~/Router`, not here. Entry points like this
 * can't be easily composed or tested
 */
import * as React from "react";
import ReactDOM from "react-dom";
import { ErrorBoundary } from "~/ErrorBoundary";
import { Router } from "~/Router";
import "./global.css";

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>{<Router />}</ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root"),
);
