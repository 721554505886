//@ts-check
import * as React from "react";
import * as Utils from "~/Utils";

/**
 * @param {{
 *   grid: JournalTypes_.Item[][][],
 *   direction: "horizontal" | "vertical",
 * }} props
 * @returns {React.ReactElement}
 */
export default function HeatMapGrid({ grid, direction }) {
  /** @type {{ row: number, col: number }} */
  const initialSelection = { row: null, col: null };
  const [selectedCell, setSelectedCell] = React.useState(initialSelection);
  const [displayLongText, setDisplayLongText] = React.useState("");

  React.useEffect(() => {
    setSelectedCell({ row: null, col: null });
  }, [Utils.int32Digest(JSON.stringify(grid))]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: direction === "horizontal" ? "column" : "row",
        }}
      >
        {grid.map((row, rowIndex) => {
          return (
            <div
              key={rowIndex}
              style={{
                display: "flex",
                flexDirection: direction === "horizontal" ? "row" : "column",
              }}
            >
              {row.map((cell, cellIndex) => {
                const totalScale = Math.min(
                  cell
                    .map(item => item.scale)
                    .reduce((partialSum, a) => partialSum + a, 0),
                  10,
                );
                return (
                  <div
                    key={cellIndex}
                    style={{
                      width: "16px",
                      height: "16px",
                      margin: "1px",
                      backgroundColor:
                        totalScale != 0
                          ? `rgba(255, 0, 0, ${totalScale / 10})`
                          : "rgba(0, 0, 0, 0.05)",
                    }}
                    onClick={() =>
                      setSelectedCell({ row: rowIndex, col: cellIndex })
                    }
                  />
                );
              })}
            </div>
          );
        })}
      </div>
      {selectedCell.row !== null &&
        selectedCell.col !== null &&
        grid[selectedCell.row][selectedCell.col].length > 0 && (
          <div>
            <div>
              Record for{" "}
              {grid[selectedCell.row][selectedCell.col][0].date.toDateString()}{" "}
            </div>
            <ul>
              {grid[selectedCell.row][selectedCell.col].map((item, idx) => {
                return (
                  <li
                    key={idx}
                    onClick={() => setDisplayLongText(item.longDescription)}
                  >
                    ({item.scale}) {item.shortDescription}
                  </li>
                );
              })}
            </ul>
            <div
              style={{
                display: displayLongText
                  ? "block"
                  : "none" /* Hidden by default */,
                position: "fixed" /* Stay in place */,
                zIndex: 1 /* Sit on top */,
                left: 0,
                top: 0,
                width: "100%" /* Full width */,
                height: "100%" /* Full height */,
                overflow: "auto" /* Enable scroll if needed */,
                backgroundColor: "rgba(0,0,0,0.4)" /* Black w/ opacity */,
              }}
            >
              <div
                style={{
                  backgroundColor: "#fefefe",
                  margin: "15% auto" /* 15% from the top and centered */,
                  padding: "20px",
                  border: "1px solid #888",
                  width:
                    "80%" /* Could be more or less, depending on screen size */,
                }}
              >
                <span
                  style={{
                    color: "#aaa",
                    float: "right",
                    fontSize: "28px",
                    fontWeight: "bold",
                  }}
                  onClick={() => setDisplayLongText("")}
                >
                  &times;
                </span>
                <p>{displayLongText}</p>
              </div>
            </div>
          </div>
        )}
    </div>
  );
}
