import * as React from "react";
import RoundTrip from "~/RoundTrip";
import Lounge from "~/Lounge";
import SimpleStage from "~/SimpleStage";
import VideoRendererEg from "~/VideoRendererEg";
import Journal from "~/Journal";
import * as Utils from "~/Utils";

export function Router() {
  const loc = window.location.pathname;
  const params = Object.fromEntries(
    new URLSearchParams(window.location.search).entries(),
  );

  const [authPassed, setAuthPassed] = React.useState(null);

  React.useEffect(() => {
    async function f() {
      const p = await authenticate(params.token);
      setAuthPassed(p);
    }
    f();
  }, []);

  if (authPassed === null) {
    return <>Authenticating...</>;
  }

  if (!authPassed) {
    return <>Prove yourself!</>;
  }

  if (loc === "/") {
    window.location.pathname = "/RoundTrip";
  }

  if (loc === "/VideoRendererEg") return <VideoRendererEg />;

  if (loc === "/RoundTrip") {
    if (!params.channelId || !params.backend) {
      params.channelId ||= randId();
      params.backend ||= "simple-stun";
      window.location.search =
        "?" + new URLSearchParams(Object.entries(params)).toString();
    }
    if (params.backend !== "agora" && params.backend !== "simple-stun") {
      return <div>Wrong backend</div>;
    }
    return (
      <RoundTrip
        channelId={params.channelId}
        token={params.token}
        backend={params.backend}
      />
    );
  }

  if (loc === "/Lounge") {
    if (!params.channelId || !params.userId || !params.backend) {
      params.channelId ||= randId();
      params.userId ||= randId();
      params.backend ||= "simple-stun";
      window.location.search =
        "?" + new URLSearchParams(Object.entries(params)).toString();
    }
    if (params.backend !== "agora" && params.backend !== "simple-stun") {
      return <div>Wrong backend</div>;
    }
    return (
      <Lounge
        channelId={params.channelId}
        userId={params.userId}
        token={params.token}
        backend={params.backend}
      />
    );
  }

  if (loc === "/SimpleStage") {
    if (!params.channelId || !params.userId || !params.role) {
      params.channelId ||= randId();
      params.userId ||= randId();
      params.role ||= "audience";
      window.location.search =
        "?" + new URLSearchParams(Object.entries(params)).toString();
    }
    if (params.role !== "host" && params.role !== "audience") {
      throw Error(`Invalid role: ${params.role}`);
    }
    console.log(params);
    return (
      <SimpleStage
        channelId={params.channelId}
        userId={params.userId}
        role={params.role}
        token={params.token}
        backend={"agora"}
      />
    );
  }

  if (loc === "/StunStage") {
    if (!params.channelId || !params.userId || !params.role) {
      params.channelId ||= randId();
      params.userId ||= randId();
      params.role ||= "audience";
      window.location.search =
        "?" + new URLSearchParams(Object.entries(params)).toString();
    }
    if (params.role !== "host" && params.role !== "audience") {
      throw Error(`Invalid role: ${params.role}`);
    }
    console.log(params);
    return (
      <SimpleStage
        channelId={params.channelId}
        userId={params.userId}
        role={params.role}
        token={params.token}
        backend={"simple-stun"}
      />
    );
  }

  if (loc === "/Journal") {
    return <Journal />;
  }

  return <div>wat</div>;
}

function randId() {
  return Math.random().toString(36).split(".")[1].slice(5);
}

/**
 * @param {string} token
 * @returns {Promise<boolean>}
 */
async function authenticate(token) {
  const endpoint = "/api/authenticate";
  const res = await fetch(Utils.urlString({ path: endpoint }), {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({
      token: token || "invalid",
    }),
  });
  if (res.status !== 200) {
    throw new Error(`request to ${endpoint} failed with ${res.status}`);
  }
  const result = await res.json();
  return result.result === "OK";
}
