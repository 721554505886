//@ts-check

/** myles's debug-oriented ui helpers, not part of AVChannel */

import * as React from "react";

const compCache = {};

export default function NSBox(namespace) {
  return [nsbox.bind(null, namespace), className.bind(null, namespace)];
}

/**
 * @param {string}               namespace  - name of module creating boxes.
 * @param {TemplateStringsArray} names      - first element is name of box,
 *                                          others ignored.
 * @returns {React.ReactElement}
 */
function nsbox(namespace, [name]) {
  // @ts-ignore
  // eslint-disable-next-line react/display-name
  return (...children) => {
    const _nsName = className(namespace, name);
    let comp;
    comp = compCache[_nsName];
    if (!comp) {
      // eslint-disable-next-line react/display-name
      comp = props => React.createElement("div", props);
      // @ts-ignore
      comp.displayName = _nsName;
      compCache[_nsName] = comp;
    }
    return React.createElement(comp, { className: _nsName }, ...children);
  };
}

/**
 * @param {string} namespace
 * @param {string} name
 */
function className(namespace, name) {
  // .n.b "∕" -> "division slash" : compart.com/en/unicode/U+2215
  const divisionSlash = "\u2215";
  return namespace + divisionSlash + name;
}
