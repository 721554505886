//@ts-check
import * as React from "react";
import { AgoraSDKService } from "~/AgoraSDKService";
import {
  AVChannelService,
  TrackPair,
  ChanSessionKey,
  useAVChannelService,
} from "~/AVChannelService";
import {
  AgoraBackendConfig,
  AgoraConnectConfig,
} from "./AgoraAVChannelService";
import {
  SimpleStunBackendConfig,
  SimpleStunConnectConfig,
} from "./SimpleStunChannelService";
import { AppError, useDispatchToErrorBoundary } from "~/ErrorBoundary";
import * as Utils from "~/Utils";
import * as VideoRenderer from "./VideoFileRenderer";

const sx = (
  /**
   * @type {string}
   */ s,
) => s;
const eidTransformer = (
  /**
   * @type {string}
   */ x,
) => x + "-secondary";

/**
 * @param {{ channelId: string; userId: string, token: string, backend: "agora" | "simple-stun" }} props
 * @returns {React.ReactElement}
 */
export default function Lounge({ channelId, userId, token, backend }) {
  // @ts-ignore
  const appId = import.meta.env.CLIENT_EXPOSED_AGORA_APP_ID;
  if (backend === "agora") {
    return (
      <AgoraSDKService>
        {sdkModule =>
          sdkModule ? (
            <AVChannelService
              config={{
                backend: "agora",
                backendConfig: new AgoraBackendConfig({
                  appId,
                  sdkModule,
                }),
              }}
            >
              <LoungeController
                cid={channelId}
                eid={userId}
                token={token}
                backend={backend}
              />
            </AVChannelService>
          ) : (
            // showing nothing is better than flashing a loading msg (for <300ms)
            <>{/* loading agora module ... */}</>
          )
        }
      </AgoraSDKService>
    );
  } else {
    return (
      <AVChannelService
        config={{
          backend: "simple-stun",
          backendConfig: new SimpleStunBackendConfig({
            wsURL: `${window.location.protocol === "https:" ? "wss" : "ws"}://${
              window.location.host
            }/ws`,
          }),
        }}
      >
        <LoungeController
          cid={channelId}
          eid={userId}
          token={token}
          backend={backend}
        />
      </AVChannelService>
    );
  }
}

/**
 * @param {Object} props
 * @param {string} props.cid
 * @param {string} props.eid
 * @param {string} props.token
 * @param {"agora" | "simple-stun"} props.backend
 * @returns {React.ReactElement}
 */
function LoungeController({ cid, eid, token, backend }) {
  const isMountedRef = React.useRef(true);
  const { avcs } = useAVChannelService();
  const dispatchToErrorBoundary = useDispatchToErrorBoundary();
  const [agoraTokens, setAgoraTokens] = React.useState([]);
  const [localMediaTrackPair, setLocalMediaTrackPair] = React.useState(
    new TrackPair({}),
  );
  const [screenshareTrack, setScreenshareTrack] = React.useState(null);

  const csi = new ChanSessionKey({ cid, eid });
  const secondaryCsi = new ChanSessionKey({ cid, eid: eidTransformer(eid) });

  const connStatus = avcs.connStatus(csi); // channel session connection status
  const pubStatus = avcs.pubStatus(csi); // channel session publish status
  const secondaryConnStatus = avcs.connStatus(secondaryCsi);
  const secondaryPubStatus = avcs.pubStatus(secondaryCsi);
  const eids = avcs.eids(csi);

  React.useEffect(() => {
    () => {
      isMountedRef.current = false;
    };
  }, []);

  React.useEffect(() => {
    Utils.callAndCatch(async () => {
      const constraints = {
        audio: true,
        video: {
          width: 320,
          height: 180,
        },
      };
      await navigator.mediaDevices
        .getUserMedia(constraints)
        .then(function (stream) {
          if (!isMountedRef.current) {
            return;
          }
          setLocalMediaTrackPair(
            new TrackPair({
              audio: stream.getAudioTracks()[0],
              video: stream.getVideoTracks()[0],
            }),
          );
        });
    }, dispatchToErrorBoundary);
  }, []);

  React.useEffect(() => {
    Utils.callAndCatch(async () => {
      const tokens = await getAgoraTokens([csi, secondaryCsi], token);
      console.log(tokens);
      if (isMountedRef.current) setAgoraTokens(tokens);
    }, dispatchToErrorBoundary);
  }, []);

  React.useEffect(() => {
    Utils.callAndCatch(async () => {
      if (!agoraTokens.length) return;
      if (connStatus === "unconnected") {
        await avcs
          .connect(
            csi,
            {
              authToken: backend === "agora" ? agoraTokens[0] : token,
            },
            backend === "agora"
              ? new AgoraConnectConfig({
                  codec: "vp8",
                  mode: "rtc",
                  role: null,
                })
              : new SimpleStunConnectConfig({ dummy: "hey" }),
          )
          .catch(dispatchToErrorBoundary);
      }
    }, dispatchToErrorBoundary);
    return () => {
      if (!isMountedRef.current) {
        avcs.disconnect(csi);
      }
    };
  });

  React.useEffect(() => {
    Utils.callAndCatch(async () => {
      if (connStatus !== "connected") return;
      if (pubStatus.audio === "unattached" && localMediaTrackPair.audio) {
        await avcs.publish(csi, localMediaTrackPair.audio);
      }
    }, dispatchToErrorBoundary);
  }, [connStatus, pubStatus, localMediaTrackPair]);

  React.useEffect(() => {
    Utils.callAndCatch(async () => {
      if (connStatus !== "connected") return;
      if (pubStatus.video === "unattached" && localMediaTrackPair.video) {
        await avcs.publish(csi, localMediaTrackPair.video);
      }
    }, dispatchToErrorBoundary);
  }, [connStatus, pubStatus, localMediaTrackPair]);

  React.useEffect(() => {
    Utils.callAndCatch(async () => {
      await Promise.all(
        // + flatmap and no await
        eids.map(async eid => {
          const subStatus = avcs.subStatus(csi, eid);
          if (subStatus.audio === "attachable") {
            await avcs.subscribe(csi, eid, "audio");
          }
          if (subStatus.video === "attachable") {
            await avcs.subscribe(csi, eid, "video");
          }
        }),
      );
    }, dispatchToErrorBoundary);
  }, [avcs.csInt32Digest(csi)]);

  React.useEffect(() => {
    Utils.callAndCatch(async () => {
      if (
        secondaryConnStatus === "connected" &&
        secondaryPubStatus.video === "unattached" &&
        screenshareTrack
      ) {
        await avcs.publish(secondaryCsi, screenshareTrack);
      }
    }, dispatchToErrorBoundary);
  }, [secondaryPubStatus, secondaryConnStatus, screenshareTrack]);

  React.useEffect(() => {
    Utils.callAndCatch(async () => {
      if (secondaryConnStatus === "connected" && !screenshareTrack) {
        await avcs.disconnect(secondaryCsi);
      }
    }, dispatchToErrorBoundary);
  }, [secondaryConnStatus, screenshareTrack]);

  return React.useMemo(() => {
    return (
      <LoungePresenter
        avcs={avcs}
        csi={csi}
        connStatus={connStatus}
        localMediaTrackPair={localMediaTrackPair}
        remoteEids={eids}
        unpublish={async mediaType => {
          if (mediaType === "audio") {
            const track = localMediaTrackPair.audio;
            setLocalMediaTrackPair(
              new TrackPair({ video: localMediaTrackPair.video }),
            );
            await avcs.unpublish(csi, track);
            track.stop();
          } else {
            const track = localMediaTrackPair.video;
            setLocalMediaTrackPair(
              new TrackPair({ audio: localMediaTrackPair.audio }),
            );
            await avcs.unpublish(csi, track);
            track.stop();
          }
        }}
        publish={async mediaType => {
          const constraints = {
            audio: mediaType === "audio",
            video:
              mediaType === "video"
                ? {
                    width: 320,
                    height: 180,
                  }
                : false,
          };
          navigator.mediaDevices
            .getUserMedia(constraints)
            .then(function (stream) {
              setLocalMediaTrackPair(
                trackPair =>
                  new TrackPair({
                    audio:
                      mediaType === "audio"
                        ? stream.getAudioTracks()[0]
                        : trackPair.audio,
                    video:
                      mediaType === "video"
                        ? stream.getVideoTracks()[0]
                        : trackPair.video,
                  }),
              );
            })
            .catch(dispatchToErrorBoundary);
        }}
        secondaryPubStatus={secondaryPubStatus}
        shareScreen={async () => {
          const constraints = {
            audio: false,
            video: {
              width: 320,
              height: 180,
            },
          };
          navigator.mediaDevices
            .getDisplayMedia(constraints)
            .then(async function (stream) {
              stream.getVideoTracks()[0].onended = () => {
                setScreenshareTrack(null);
              };
              setScreenshareTrack(stream.getVideoTracks()[0]);
              await avcs.connect(
                secondaryCsi,
                { authToken: backend === "agora" ? agoraTokens[1] : token },
                backend === "agora"
                  ? new AgoraConnectConfig({
                      codec: "vp8",
                      mode: "rtc",
                      role: null,
                    })
                  : new SimpleStunConnectConfig({ dummy: "hey" }),
              );
            })
            .catch(e => {
              if (
                e instanceof DOMException &&
                (e.message === "Permission denied" ||
                  e.name === "NotAllowedError")
              ) {
                window.alert("Please grant screenshare permission");
              } else {
                throw e;
              }
            });
        }}
        unshareScreen={async () => {
          let track = screenshareTrack;
          setScreenshareTrack(null);
          await avcs.unpublish(secondaryCsi, track);
          track.stop();
          await avcs.disconnect(secondaryCsi);
        }}
      />
    );
  }, [
    localMediaTrackPair,
    avcs.csInt32Digest(csi),
    avcs.csInt32Digest(secondaryCsi),
  ]);
}

/**
 * @param {Object} props
 * @param {AVChannelService_.API} props.avcs
 * @param {ChanSessionKey} props.csi
 * @param {AVChannelService_.ConnStatus} props.connStatus
 * @param {TrackPair} props.localMediaTrackPair
 * @param {string[]} props.remoteEids
 * @param {import("~/AVChannelService").PubStatus} props.secondaryPubStatus
 * @param {(mediaType: 'audio' | 'video') => Promise<void>} props.publish
 * @param {(mediaType: 'audio' | 'video') => Promise<void>} props.unpublish
 * @param {() => Promise<void>} props.shareScreen
 * @param {() => Promise<void>} props.unshareScreen
 * @returns {React.ReactElement}
 */
function LoungePresenter({
  avcs,
  csi,
  connStatus,
  localMediaTrackPair,
  remoteEids,
  unpublish,
  publish,
  secondaryPubStatus,
  shareScreen,
  unshareScreen,
}) {
  return (
    <div className={sx("Root")}>
      {connStatus && connStatus === "connected"
        ? "Connected"
        : "Connecting ..."}{" "}
      to channel
      {
        <div className={sx("Streams")}>
          <div className={sx("SubscribedStream")}>
            <div className={sx("Header")}>Subscribed channel streams</div>
            <Utils.Frag>
              {() => {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      flexWrap: "wrap",
                    }}
                  >
                    {!remoteEids
                      ? null
                      : _renderStreams(
                          avcs,
                          remoteEids,
                          csi,
                          localMediaTrackPair,
                        )}
                  </div>
                );
              }}
            </Utils.Frag>
            <div style={{ textAlign: "center" }}>
              <>
                {localMediaTrackPair.video ? (
                  <img
                    id="mute_cam"
                    key="mute_cam"
                    src="videocam.png"
                    style={{ height: "40px", width: "56px" }}
                    onClick={async () => {
                      await unpublish("video");
                    }}
                  />
                ) : (
                  <img
                    id="unmute_cam"
                    key="unmute_cam"
                    src="videocam-muted.png"
                    style={{ height: "40px", width: "56px" }}
                    onClick={async () => {
                      await publish("video");
                    }}
                  />
                )}
                {localMediaTrackPair.audio ? (
                  <img
                    id="mute_mic"
                    key="mute_mic"
                    src="mic.png"
                    style={{ height: "40px", width: "56px" }}
                    onClick={async () => {
                      await unpublish("audio");
                    }}
                  />
                ) : (
                  <img
                    id="unmute_mic"
                    key="unmute_mic"
                    src="mic-muted.png"
                    style={{ height: "40px", width: "56px" }}
                    onClick={async () => {
                      await publish("audio");
                    }}
                  />
                )}
                {secondaryPubStatus.video === "attached" ? (
                  <img
                    id="stop_screenshare"
                    key="stop_screenshare"
                    src="screenshare-stop.png"
                    style={{ height: "40px", width: "56px" }}
                    onClick={async () => {
                      await unshareScreen();
                    }}
                  />
                ) : (
                  <img
                    id="start_screenshare"
                    key="start_screenshare"
                    src="screenshare.png"
                    style={{ height: "40px", width: "56px" }}
                    onClick={async () => {
                      await shareScreen();
                    }}
                  />
                )}
              </>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

/**
 * @param {AVChannelService_.API} avcs
 * @param {string[]} remoteEids
 * @param {ChanSessionKey} csi
 * @param {TrackPair} localMediaTrackPair
 * @returns
 */
function _renderStreams(avcs, remoteEids, csi, localMediaTrackPair) {
  const localStream = (
    <div key={csi.eid}>
      {localMediaTrackPair.video ? (
        <VideoRenderer.FromTracks
          key={`${csi.eid}-player`}
          mediaTrackPair={new TrackPair({ video: localMediaTrackPair.video })}
          styleStr={
            "width: 320px; height: 180px; -webkit-transform: scaleX(-1); transform: scaleX(-1);"
          }
        />
      ) : (
        <div
          style={{
            width: "320px",
            height: "180px",
            backgroundColor: "black",
          }}
        ></div>
      )}
      <div style={{ textAlign: "center" }}>
        {csi.eid} (You)
        {localMediaTrackPair.audio ? null : "(muted)"}
      </div>
    </div>
  );
  const streams = remoteEids.map(remoteEid => {
    const subStatus = avcs.subStatus(csi, remoteEid);
    const trackPair = avcs.getSubTrackPair(csi, remoteEid);
    // + is switch/case better?
    if (subStatus.video === "attached" || subStatus.audio === "attached") {
      return (
        <div key={remoteEid}>
          {subStatus.video === "attached" ? (
            <VideoRenderer.FromTracks
              key={`${remoteEid}-player`}
              mediaTrackPair={trackPair}
              styleStr={"width: 320px; height: 180px;"}
            />
          ) : (
            <div
              style={{
                width: "320px",
                height: "180px",
                backgroundColor: "black",
              }}
            ></div>
          )}
          <div style={{ textAlign: "center" }}>
            {remoteEid}
            {subStatus.audio === "attached" ? null : "(muted)"}
          </div>
        </div>
      );
    } else if (
      subStatus.combinedTracks === "attachable" ||
      subStatus.combinedTracks === "attaching"
    ) {
      return <div key={remoteEid}>Subscribing to {remoteEid}</div>;
    } else {
      return <div key={remoteEid}>{remoteEid} doesn't publish</div>;
    }
  });
  streams.push(localStream);
  streams.sort((a, b) => a.key.toString().localeCompare(b.key.toString()));
  return streams;
}

/**
 * @param {ChanSessionKey[]} agoraTokenParams
 * @param {string} token
 * @returns {Promise<string[]>}
 */
async function getAgoraTokens(agoraTokenParams, token) {
  console.log(agoraTokenParams);
  const endpoint = "/api/getAgoraTokens";
  const res = await fetch(Utils.urlString({ path: endpoint }), {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({
      params: agoraTokenParams.map(({ cid, eid }) => ({
        cid,
        eid,
      })),
      token,
    }),
  });
  if (res.status !== 200)
    throw AppError(
      "getAgoraTokensRequestFailed",
      `request to ${endpoint} failed with ${res.status}`,
    );
  const tokens = await res.json();
  return tokens;
}

function __log([prefix]) {
  return arg => {
    console.log("[" + prefix + "]", arg);
  };
}
