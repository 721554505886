//@ts-check
import * as React from "react";

export class ErrorBoundary extends React.Component {
  // + jsdoc state member interface: { error: Error? }

  constructor(props) {
    super(props);
    this.state = { error: null, external: false };
    this.globalErrorEventListener = this.globalErrorEventListener.bind(this);
  }

  globalErrorEventListener(errorEventObj) {
    let error,
      uncaughtPromise = false;
    if (errorEventObj instanceof ErrorEvent) {
      // handle non-rendering error here.
      error = errorEventObj.error;
    } else if (errorEventObj instanceof PromiseRejectionEvent) {
      // handle uncaught promise event here.
      error = errorEventObj.reason;
      uncaughtPromise = true;
    }
    this.setState({ error, external: true, uncaughtPromise });
  }

  componentDidMount() {
    // This listener will catch any non-rendering errors(e.g. event handlers.)
    window.addEventListener("error", this.globalErrorEventListener);
    window.addEventListener(
      "unhandledrejection",
      this.globalErrorEventListener,
    );
  }

  componentWillUnmount() {
    window.removeEventListener(
      "unhandledrejection",
      this.globalErrorEventListener,
    );
    window.removeEventListener("error", this.globalErrorEventListener);
  }

  // static getDerivedStateFromError(error) {
  //   return { error, external: false }
  // }

  componentDidCatch(error, { componentStack }) {
    this.setState({
      error,
      componentStack,
      external: false,
    });
    // @todo: Send to Sentry.
  }

  render() {
    const error = this.state.error;
    if (error)
      return (
        <>
          {this.state.external && (
            <span style={{ color: "red" }}>
              Error was raised outside the component tree
              {this.state.uncaughtPromise && <b> from a Promise</b>}. This
              should not happen! Find out which component is triggering this
              error and pass the error to the return value of the
              useDispatchToErrorBoundary hook
            </span>
          )}
          {!this.state.external && (
            <pre>{error.name + "\n" + this.state.componentStack}</pre>
          )}
          <pre>{error.stack}</pre>
        </>
      );
    return <>{this.props.children}</>;
  }
}

export function useDispatchToErrorBoundary() {
  const [__, setState] = React.useState();
  return err => {
    setState(() => {
      throw err;
    });
  };
}

/**
 * @example
 *
 *   throw AppError("requestFailed", `request to ${url} returned a 500 status`);
 *
 */
export function AppError(name, msg) {
  const e = new Error(msg);
  e.name = name;
  return e;
}
