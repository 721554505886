//@ts-check
import * as React from "react";
import { useDispatchToErrorBoundary, AppError } from "~/ErrorBoundary";

const Context = React.createContext(null);

/**
 * Async loads agora sdk and makes it available to consumers.
 *
 * @param {{
 *   children: (_sdkModule: AgoraRTC_.IAgoraRTC) => React.ReactElement;
 * }} props
 */

export function AgoraSDKService({ children }) {
  const [sdkModule, setSdkModule] = React.useState(null);

  // + inject log capture shim

  React.useEffect(() => {
    if (sdkModule) return;

    import("agora-rtc-sdk-ng").then(mod => {
      setSdkModule(mod);
    });
  }, []);

  React.useEffect(() => {
    if (!sdkModule) return;
    // agoraRtc.setParameter('SUBSCRIBE_TCC', false) // ~ what do?
    sdkModule.disableLogUpload();
  }, [sdkModule]);

  return (
    <Context.Provider {...{ value: sdkModule }}>
      {children(sdkModule)}
    </Context.Provider>
  );
}

/**
 * Possibly not needed, if the AVChannelService is always a direct descendant of
 * this component then passing via props is fine. but there may be components in
 * adair that expect to consume the sdk directly so leaving this in for them.
 *
 * Alternatively, we could assign the async loaded sdkModule to window.AgoraRTC
 * and let them consume it that way. once we've moved everything over to
 * AVChannelService we could then just remove that global assignment
 */
export function __useAgoraSDKService() {
  const dispatchToErrorBoundary = useDispatchToErrorBoundary();
  const agoraRtc = React.useContext(Context);
  if (!agoraRtc) {
    dispatchToErrorBoundary(
      AppError(
        "AgoraRTCNotLoaded",
        "must be called in a descendant component of AgoraSDKService, and only after that component has invoked its onLoaded callback",
      ),
    );
  }
  return agoraRtc;
}
