//@ts-check
import * as React from "react";
import { produce } from "immer";
import * as Utils from "~/Utils";

const Context = React.createContext(null);

/**
 *
 * @param {{ children: React.ReactElement }} props
 * @returns {React.ReactElement}
 */
export function JournalService({ children }) {
  /** @type {{ [year: number]: JournalTypes_.Item[][]}} */
  const emptyItems = {};
  const [items, setItems] = React.useState(emptyItems);
  /** @type {(f: (draft: { [year: number]: JournalTypes_.Item[][]}) => void) => void} */
  const produceItems = f => setItems(produce(f));

  const fetchItemsForYear = React.useCallback(
    async year => {
      const startDate = new Date(`1/1/${year}`);
      const endDate = new Date(`12/31/${year}`);
      const items = await getJournalItems(startDate, endDate);
      items.sort((a, b) => a.date.getTime() - b.date.getTime());
      /** @type {JournalTypes_.Item[][]} */
      const ret = [];
      const date = new Date(startDate);
      let itemIdx = 0;
      while (date <= endDate) {
        /** @type {JournalTypes_.Item[]} */
        const row = [];
        for (
          ;
          itemIdx < items.length &&
          items[itemIdx].date.getTime() === date.getTime();
          itemIdx += 1
        ) {
          row.push(items[itemIdx]);
        }
        if (row.length > 0) {
          ret.push(row);
        }
        date.setDate(date.getDate() + 1);
      }
      produceItems(draft => {
        draft[year] = ret;
      });
    },
    [Utils.int32Digest(JSON.stringify(items))],
  );

  React.useEffect(() => {
    const today = new Date();
    fetchItemsForYear(today.getFullYear());
  }, []);

  /** @type {JournalService_.API} */
  const journalService = {
    items,
    digestForYear(year) {
      return Utils.int32Digest(
        JSON.stringify(journalService.items[year] || {}),
      );
    },
    fetchItemsForYear,
    async recordItem(item) {
      await recordJournalItem(item);
      await fetchItemsForYear(item.date.getFullYear());
    },
  };
  return (
    <Context.Provider value={{ journalService }}>{children}</Context.Provider>
  );
}

/** @type {() => { journalService: JournalService_.API }} */
export function useJournalService() {
  const journalService = React.useContext(Context);
  if (!journalService) {
    throw new Error("Must be used in a descendant of JournalService");
  }
  return journalService;
}

/**
 * @param {Date} startDate
 * @param {Date} endDate
 * @returns {Promise<JournalTypes_.Item[]>}
 */
async function getJournalItems(startDate, endDate) {
  const endpoint = "/api/getJournalItems";
  const res = await fetch(Utils.urlString({ path: endpoint }), {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({
      startDate: `${
        startDate.getMonth() + 1
      }/${startDate.getDate()}/${startDate.getFullYear()}`,
      endDate: `${
        endDate.getMonth() + 1
      }/${endDate.getDate()}/${endDate.getFullYear()}`,
    }),
  });
  if (res.status !== 200) {
    throw new Error(`request to ${endpoint} failed with ${res.status}`);
  }
  const items = await res.json();
  return items.map(item => {
    return {
      date: new Date(item["date"]),
      scale: item["scale"],
      shortDescription: item["shortDescription"],
      longDescription: item["longDescription"],
    };
  });
}

/**
 * @param {JournalTypes_.Item} item
 * @returns {Promise<void>}
 */
async function recordJournalItem(item) {
  const endpoint = "/api/recordJournalItem";
  const res = await fetch(Utils.urlString({ path: endpoint }), {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({
      scale: item.scale,
      date: `${
        item.date.getMonth() + 1
      }/${item.date.getDate()}/${item.date.getFullYear()}`,
      shortDescription: item.shortDescription,
      longDescription: item.longDescription,
    }),
  });
  if (res.status !== 200) {
    throw new Error(`request to ${endpoint} failed with ${res.status}`);
  }
  await res.json();
}
