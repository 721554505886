//@ts-check
import * as React from "react";
import { useDispatchToErrorBoundary, AppError } from "~/ErrorBoundary";
import {
  AgoraAVChannelService,
  AgoraBackendConfig,
} from "~/AgoraAVChannelService";
import {
  SimpleStunChannelService,
  SimpleStunBackendConfig,
} from "~/SimpleStunChannelService";

/**
 * @type AVChannelService_.default
 */
export function AVChannelService({ config, children }) {
  switch (config.backend) {
    case "agora":
      if (config.backendConfig instanceof AgoraBackendConfig) {
        return (
          <AgoraAVChannelService config={config.backendConfig}>
            {children}
          </AgoraAVChannelService>
        );
      }
      throw AppError("Agora backend should have Agora backend config");
    case "simple-stun":
      if (config.backendConfig instanceof SimpleStunBackendConfig) {
        return (
          <SimpleStunChannelService config={config.backendConfig}>
            {children}
          </SimpleStunChannelService>
        );
      }
      throw AppError(
        "SimpleStun backend should have simple stun backend config",
      );
    default:
      throw AppError(
        "UnsupportedBackend",
        "only agora backend supported at this time",
      );
  }
}

/**
 * @function
 * @returns {{ avcs: AVChannelService_.API }}
 */
export function useAVChannelService() {
  const dispatchToErrorBoundary = useDispatchToErrorBoundary();
  const avcs = React.useContext(_Context);
  if (!avcs) {
    dispatchToErrorBoundary(
      AppError(
        "AVChannelServiceNotProvided",
        "must be used in a descendant of AVChannelService",
      ),
    );
  }
  return avcs;
}

export const _Context = React.createContext(null);

/**
 * @class
 */
export class ChanSessionKey {
  /**
   * @type {string}
   */
  cid = null;
  /**
   * @type {string}
   */
  eid = null;
  /**
   * @param {{ cid: string; eid: string }} props
   */
  constructor(props) {
    Object.assign(this, props);
  }
  /**
   * @type {string}
   */
  get strKey() {
    return this.cid + "#" + this.eid;
  }
}

/**
 * @class
 */
export class TrackPair {
  /**
   * @type {MediaStreamTrack | null}
   */
  audio = null;
  /**
   * @type {MediaStreamTrack | null}
   */
  video = null;
  /**
   * @param {{ audio?: ?MediaStreamTrack; video?: ?MediaStreamTrack }} props
   */
  constructor({ audio = null, video = null }) {
    Object.assign(this, { audio, video });
  }
  /**
   * @type {number}
   */
  get count() {
    return +!!this.video + +!!this.audio;
  }
}

/**
 * @class
 */
export class SubStatus {
  /**
   * @type {AVChannelService_.TrackSubStatus}
   */
  audio = "unattached";
  /**
   * @type {AVChannelService_.TrackSubStatus}
   */
  video = "unattached";
  /**
   * @param {{
   *   audio?: AVChannelService_.TrackSubStatus;
   *   video?: AVChannelService_.TrackSubStatus;
   * }} props
   */
  constructor({ audio = "unattached", video = "unattached" }) {
    Object.assign(this, { audio, video });
  }
  /**
   * @type {AVChannelService_.TrackSubStatus}
   */
  get combinedTracks() {
    if (this.audio == "unattached" || this.video == "unattached") {
      return "unattached";
    } else if (this.audio == "attachable" || this.video == "attachable") {
      return "attachable";
    } else if (this.audio == "attaching" || this.video == "attaching") {
      return "attaching";
    } else {
      return "attached";
    }
  }
}

/**
 * @class
 */
export class PubStatus {
  /**
   * @type {AVChannelService_.TrackPubStatus}
   */
  audio = "unattached";
  /**
   * @type {AVChannelService_.TrackPubStatus}
   */
  video = "unattached";
  /**
   * @param {{
   *   audio?: AVChannelService_.TrackPubStatus;
   *   video?: AVChannelService_.TrackPubStatus;
   * }} props
   */
  constructor({ audio = "unattached", video = "unattached" }) {
    Object.assign(this, { audio, video });
  }
  /**
   * @type {AVChannelService_.TrackPubStatus}
   */
  get combinedTracks() {
    if (this.audio == "unattached" || this.video == "unattached") {
      return "unattached";
    } else if (this.audio == "attaching" || this.video == "attaching") {
      return "attaching";
    } else {
      return "attached";
    }
  }
}
