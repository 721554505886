//@ts-check
import * as React from "react";
import { JournalService, useJournalService } from "~/JournalService";
import HeatMapGrid from "~/HeatMapGrid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

/**
 * @returns {React.ReactElement}
 */
export default function Journal() {
  return (
    <JournalService>
      <JournalController />
    </JournalService>
  );
}

/**
 * @returns {React.ReactElement}
 */
function JournalController() {
  const { journalService } = useJournalService();

  const today = new Date();
  const [year, setYear] = React.useState(today.getFullYear());
  const yearInputRef = React.useRef(null);
  /** @type {JournalTypes_.Item[][][]} */
  const initialGrid = [];
  const [grid, setGrid] = React.useState(initialGrid);

  React.useEffect(() => {
    journalService.fetchItemsForYear(year);
  }, [year]);

  React.useEffect(() => {
    if (!journalService.items[year]) {
      return;
    }
    const items = journalService.items[year];
    const startDate = new Date(`1/1/${year}`);
    const endDate = new Date(`12/31/${year}`);
    // start on a Sunday
    const displayStart = startDate;
    displayStart.setDate(displayStart.getDate() - displayStart.getDay());
    // end on a Saturday
    const displayEnd = endDate;
    displayEnd.setDate(displayEnd.getDate() + (6 - endDate.getDay()));
    /** @type {JournalTypes_.Item[][][]} */
    const grid = [];
    let date = displayStart;
    let itemIdx = 0;
    while (date < displayEnd) {
      /** @type {JournalTypes_.Item[][]} */
      const row = [];
      for (let i = 0; i < 7; i += 1) {
        /** @type {JournalTypes_.Item[]} */
        let cell = [];
        const d = new Date(date);
        d.setDate(d.getDate() + i);
        if (
          itemIdx < items.length &&
          items[itemIdx][0].date.getTime() === d.getTime()
        ) {
          cell = items[itemIdx];
          itemIdx += 1;
        }
        row.push(cell);
      }
      grid.push(row);
      date = new Date(date);
      date.setDate(date.getDate() + 7);
    }
    setGrid(grid);
  }, [year, journalService.digestForYear(year)]);

  if (grid) {
    return (
      <div>
        <JournalPresenter grid={grid} />
        <div>
          <input type="text" ref={yearInputRef} />
          <button
            onClick={() => {
              setYear(Number.parseInt(yearInputRef.current.value));
            }}
          >
            Set Year
          </button>
        </div>
        <NewJournalItemRecorder today={today} />
      </div>
    );
  } else {
    return <>Loading</>;
  }
}

/**
 * @param {Object} props
 * @param {JournalTypes_.Item[][][]} props.grid
 * @returns {React.ReactElement}
 */
function JournalPresenter({ grid }) {
  return <HeatMapGrid grid={grid} direction="vertical" />;
}

/**
 * @param {Object} props
 * @param {Date} props.today
 * @returns {React.ReactElement}
 */
function NewJournalItemRecorder({ today }) {
  const { journalService } = useJournalService();
  const shortDescRef = React.useRef(null);
  const longDescRef = React.useRef(null);
  const [itemDate, setItemDate] = React.useState(today);
  const [scale, setScale] = React.useState(1);
  const [shortDesc, setShortDesc] = React.useState("");
  const [longDesc, setLongDesc] = React.useState("");
  return (
    <div>
      <DatePicker
        selected={itemDate}
        onChange={date => {
          setItemDate(date);
        }}
      />
      <div>
        <select onChange={e => setScale(Number.parseInt(e.target.value))}>
          <option value="1" selected={scale === 1}>
            1
          </option>
          <option value="2" selected={scale === 2}>
            2
          </option>
          <option value="3" selected={scale === 3}>
            3
          </option>
          <option value="4" selected={scale === 4}>
            4
          </option>
          <option value="5" selected={scale === 5}>
            5
          </option>
        </select>
      </div>
      <div>
        <input
          ref={shortDescRef}
          type="text"
          onChange={e => setShortDesc(e.target.value)}
        />
      </div>
      <div>
        <textarea
          ref={longDescRef}
          rows={10}
          cols={50}
          onChange={e => setLongDesc(e.target.value)}
        />
      </div>
      <div>
        <button
          disabled={!shortDesc || !longDesc}
          onClick={async () => {
            await journalService.recordItem({
              scale: scale,
              date: itemDate,
              shortDescription: shortDesc,
              longDescription: longDesc,
            });
            setScale(1);
            setShortDesc("");
            setLongDesc("");
            shortDescRef.current.value = "";
            longDescRef.current.value = "";
          }}
        >
          Submit
        </button>
      </div>
    </div>
  );
}
