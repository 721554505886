//@ts-check
import * as React from "react";
import { produce } from "immer";
import * as Utils from "~/Utils";
import { _Context, TrackPair, PubStatus, SubStatus } from "./AVChannelService";

/**
 * class
 */
export class SimpleStunBackendConfig {
  /**
   * @type {string}
   */
  wsURL = "";
  /**
   * @param {{ wsURL: string }} props
   */
  constructor(props) {
    Object.assign(this, props);
  }
}

/**
 * @class
 */
export class SimpleStunConnectConfig {
  /**
   * @type {string}
   */
  dummy = "";
  /**
   * @param {{ dummy: string }} props
   */
  constructor(props) {
    Object.assign(this, props);
  }
}

/**
 * @param {Object} props
 * @param {SimpleStunBackendConfig} props.config
 * @param {React.ReactElement} props.children
 * @returns {React.ReactElement}
 */
export function SimpleStunChannelService({ config, children }) {
  /**
   * @typedef ChannelSessionStateItem
   * @property {AVChannelService_.ConnStatus} connStatus
   * @property {{
   *   audio: AVChannelService_.TrackPubStatus;
   *   video: AVChannelService_.TrackPubStatus;
   * }} publishStatus
   * @property {Object.<
   *   string,
   *   {
   *     audio: AVChannelService_.TrackSubStatus;
   *     video: AVChannelService_.TrackSubStatus;
   *   }
   * >} subscriptionStatuses
   * @property {{socket: WebSocket, audioTrack?: MediaStreamTrack, videoTrack?: MediaStreamTrack}} sessionInternalState
   * @property {Object.<string, {peerConnection: RTCPeerConnection, audioTrack?: MediaStreamTrack, videoTrack?: MediaStreamTrack}>} entryInternalState
   */
  /**
   * @typedef {Object.<string, ChannelSessionStateItem>} StateType
   */
  /**
   * @type StateType
   */
  const initialState = {};
  const [state, setState] = React.useState(initialState);
  /**
   * @callback stateTransformer
   * @param {StateType} draft
   * @returns {void}
   */
  /**
   * @function
   * @param {stateTransformer} f
   */
  const produceState = f => setState(produce(f));

  const wsURL = config.wsURL;

  const stateRef = React.useRef(initialState);
  stateRef.current = state;
  const cskPubCountRef = React.useRef(
    /** @type {Object.<string, number>} */ {},
  );

  const handlePeer = function (
    /** @type {import("~/AVChannelService").ChanSessionKey} */ csk,
    data,
  ) {
    const pc = new RTCPeerConnection(SERVER);
    pc.onicecandidate = function (event) {
      if (event.candidate) {
        stateRef.current[csk.strKey].sessionInternalState.socket.send(
          JSON.stringify({
            eventName: "send_ice_candidate",
            data: {
              label: event.candidate.sdpMLineIndex,
              candidate: event.candidate.candidate,
              receiver: data.eid,
              channel: csk.cid,
              eid: csk.eid,
            },
          }),
        );
      }
    };
    pc.ontrack = function (event) {
      produceState(draft => {
        const track = event.track;
        if (track.kind === "audio") {
          draft[csk.strKey].subscriptionStatuses[data.eid].audio = "attachable";
          draft[csk.strKey].entryInternalState[data.eid].audioTrack = track;
          track.onmute = function (__event) {
            produceState(draft => {
              if (!draft[csk.strKey].subscriptionStatuses[data.eid]) {
                return;
              }
              draft[csk.strKey].subscriptionStatuses[data.eid].audio =
                "unattached";
              draft[csk.strKey].entryInternalState[data.eid].audioTrack = null;
            });
          };
        } else {
          draft[csk.strKey].subscriptionStatuses[data.eid].video = "attachable";
          draft[csk.strKey].entryInternalState[data.eid].videoTrack = track;
          track.onmute = function (__event) {
            produceState(draft => {
              if (!draft[csk.strKey].subscriptionStatuses[data.eid]) {
                return;
              }
              draft[csk.strKey].subscriptionStatuses[data.eid].video =
                "unattached";
              draft[csk.strKey].entryInternalState[data.eid].videoTrack = null;
            });
          };
        }
      });
    };
    if (stateRef.current[csk.strKey].sessionInternalState.audioTrack) {
      pc.addTrack(stateRef.current[csk.strKey].sessionInternalState.audioTrack);
    }
    if (stateRef.current[csk.strKey].sessionInternalState.videoTrack) {
      pc.addTrack(stateRef.current[csk.strKey].sessionInternalState.videoTrack);
    }
    pc.onnegotiationneeded = function (__event) {
      sendOffer(
        csk,
        data.eid,
        pc,
        stateRef.current[csk.strKey].sessionInternalState.socket,
      );
    };
    // + track removed
    produceState(draft => {
      draft[csk.strKey].subscriptionStatuses[data.eid] = {
        audio: "unattached",
        video: "unattached",
      };
      draft[csk.strKey].entryInternalState[data.eid] = { peerConnection: pc };
    });
  };

  /**
   * @type {AVChannelService_.API}
   */
  const avcs = {
    async connect(csk, config, backendSpecificConfig) {
      if (!(backendSpecificConfig instanceof SimpleStunConnectConfig)) {
        throw new Error("Wrong config for connect");
      }
      const socket = new WebSocket(wsURL);
      socket.onopen = function () {
        socket.send(
          JSON.stringify({
            eventName: "join_channel",
            data: {
              channel: csk.cid,
              eid: csk.eid,
              token: config.authToken,
            },
          }),
        );
      };
      socket.onerror = function (err) {
        console.error("onerror");
        console.error(err);
      };
      socket.onclose = function (__data) {
        produceState(draft => {
          delete draft[csk.strKey];
        });
      };
      socket.onmessage = function (msg) {
        const json = JSON.parse(msg.data);
        const data = json.data;
        var pc;
        switch (json.eventName) {
          case "new_peer_connected":
            handlePeer(csk, data);
            break;
          case "get_peers":
            data.remoteEids.forEach(eid => {
              handlePeer(csk, { eid: eid });
            });
            produceState(draft => {
              draft[csk.strKey].connStatus = "connected";
            });
            break;
          case "join_channel_rejected":
            throw Error("Join channel failed");
          case "receive_offer":
            pc =
              stateRef.current[csk.strKey].entryInternalState[data.eid]
                .peerConnection;
            pc.setRemoteDescription(new RTCSessionDescription(data.sdp));
            pc.createAnswer().then(function (session_description) {
              pc.setLocalDescription(session_description);
              stateRef.current[csk.strKey].sessionInternalState.socket.send(
                JSON.stringify({
                  eventName: "send_answer",
                  data: {
                    channel: csk.cid,
                    eid: csk.eid,
                    sdp: session_description,
                    receiver: data.eid,
                  },
                }),
              );
            });
            break;
          case "receive_answer":
            pc =
              stateRef.current[csk.strKey].entryInternalState[data.eid]
                .peerConnection;
            pc.setRemoteDescription(new RTCSessionDescription(data.sdp));
            break;
          case "receive_ice_candidate":
            var candidate = new RTCIceCandidate({
              ...data,
              sdpMLineIndex: 0,
            });
            stateRef.current[csk.strKey].entryInternalState[
              data.eid
            ].peerConnection.addIceCandidate(candidate);
            break;
          case "remove_peer_connected":
            produceState(draft => {
              delete draft[csk.strKey].subscriptionStatuses[data.eid];
              delete draft[csk.strKey].entryInternalState[data.eid];
            });
            break;
        }
      };
      produceState(draft => {
        draft[csk.strKey] = {
          connStatus: "connecting",
          publishStatus: { audio: "unattached", video: "unattached" },
          subscriptionStatuses: {},
          sessionInternalState: {
            socket,
          },
          entryInternalState: {},
        };
      });
    },
    async disconnect(csk) {
      state[csk.strKey].sessionInternalState.socket.close();
    },
    connStatus(csk) {
      return state[csk.strKey] ? state[csk.strKey].connStatus : "unconnected";
    },
    async publish(csk, track) {
      if (!cskPubCountRef.current[csk.strKey]) {
        cskPubCountRef.current[csk.strKey] = 0;
      }
      cskPubCountRef.current[csk.strKey] =
        cskPubCountRef.current[csk.strKey] + 1;
      for (const [__eid, entry] of Object.entries(
        state[csk.strKey].entryInternalState,
      )) {
        entry.peerConnection.addTrack(track);
        /*
        sendOffer(
          csk,
          eid,
          entry.peerConnection,
          state[csk.strKey].sessionInternalState.socket,
        );
        */
      }
      produceState(draft => {
        if (track.kind === "audio") {
          draft[csk.strKey].publishStatus.audio = "attached";
          draft[csk.strKey].sessionInternalState.audioTrack = track;
        } else {
          draft[csk.strKey].publishStatus.video = "attached";
          draft[csk.strKey].sessionInternalState.videoTrack = track;
        }
      });
    },
    async unpublish(csk, track) {
      for (const [__eid, entry] of Object.entries(
        state[csk.strKey].entryInternalState,
      )) {
        entry.peerConnection.getSenders().forEach(sender => {
          if (sender.track === track) {
            entry.peerConnection.removeTrack(sender);
          }
        });
      }
      produceState(draft => {
        if (track.kind === "audio") {
          draft[csk.strKey].publishStatus.audio = "unattached";
          draft[csk.strKey].sessionInternalState.audioTrack = track;
        } else {
          draft[csk.strKey].publishStatus.video = "unattached";
          draft[csk.strKey].sessionInternalState.videoTrack = track;
        }
      });
    },
    pubStatus(csk) {
      return state[csk.strKey]
        ? new PubStatus(state[csk.strKey].publishStatus)
        : new PubStatus({});
    },
    async subscribe(csk, eid, mediaType) {
      produceState(draft => {
        if (mediaType === "audio") {
          draft[csk.strKey].subscriptionStatuses[eid].audio = "attached";
        } else {
          draft[csk.strKey].subscriptionStatuses[eid].video = "attached";
        }
      });
    },
    async unsubscribe(csk, eid, mediaType) {
      produceState(draft => {
        if (mediaType === "audio") {
          draft[csk.strKey].subscriptionStatuses[eid].audio = "attachable";
        } else {
          draft[csk.strKey].subscriptionStatuses[eid].video = "attachable";
        }
      });
    },
    eids(csk) {
      return state[csk.strKey]
        ? Object.keys(state[csk.strKey].subscriptionStatuses)
        : [];
    },
    getSubTrackPair(csk, eid) {
      return state[csk.strKey]?.entryInternalState[eid]
        ? new TrackPair({
            audio: state[csk.strKey].entryInternalState[eid].audioTrack,
            video: state[csk.strKey].entryInternalState[eid].videoTrack,
          })
        : new TrackPair({});
    },
    subStatus(csk, eid) {
      return state[csk.strKey] && state[csk.strKey].subscriptionStatuses[eid]
        ? new SubStatus(state[csk.strKey].subscriptionStatuses[eid])
        : new SubStatus({});
    },
    csInt32Digest(csk) {
      if (!state[csk.strKey]) {
        return 0;
      }
      const serializableState = {
        connStatus: state[csk.strKey].connStatus,
        publishStatus: state[csk.strKey].publishStatus,
        subscriptionStatuses: state[csk.strKey].subscriptionStatuses,
      };
      return Utils.int32Digest(JSON.stringify(serializableState));
    },
  };
  return <_Context.Provider value={{ avcs }}>{children}</_Context.Provider>;
}

const SERVER = {
  iceServers: [
    {
      urls: ["stun:stun.l.google.com:19302"],
    },
  ],
};

/**
 * @param {import("~/AVChannelService").ChanSessionKey} csk
 * @param {string} eid
 * @param {RTCPeerConnection} pc
 * @param {WebSocket} socket
 */
function sendOffer(csk, eid, pc, socket) {
  pc.createOffer().then(function (session_description) {
    session_description.sdp = preferOpus(session_description.sdp);
    pc.setLocalDescription(session_description);
    socket.send(
      JSON.stringify({
        eventName: "send_offer",
        data: {
          sdp: session_description,
          receiver: eid,
          channel: csk.cid,
          eid: csk.eid,
        },
      }),
    );
  });
}

/**
 *
 * @param {string} sdp
 * @returns {string}
 */
function preferOpus(sdp) {
  var sdpLines = sdp.split("\r\n");
  var mLineIndex = null;
  // Search for m line.
  for (var i = 0; i < sdpLines.length; i++) {
    if (sdpLines[i].search("m=audio") !== -1) {
      mLineIndex = i;
      break;
    }
  }
  if (mLineIndex === null) return sdp;

  // If Opus is available, set it as the default in m line.
  for (var j = 0; j < sdpLines.length; j++) {
    if (sdpLines[j].search("opus/48000") !== -1) {
      var opusPayload = extractSdp(sdpLines[j], /:(\d+) opus\/48000/i);
      if (opusPayload)
        sdpLines[mLineIndex] = setDefaultCodec(
          sdpLines[mLineIndex],
          opusPayload,
        );
      break;
    }
  }

  // Remove CN in m line and sdp.
  sdpLines = removeCN(sdpLines, mLineIndex);

  sdp = sdpLines.join("\r\n");
  return sdp;
}

function extractSdp(sdpLine, pattern) {
  var result = sdpLine.match(pattern);
  return result && result.length == 2 ? result[1] : null;
}

function setDefaultCodec(mLine, payload) {
  var elements = mLine.split(" ");
  var newLine = [];
  var index = 0;
  for (var i = 0; i < elements.length; i++) {
    if (index === 3)
      // Format of media starts from the fourth.
      newLine[index++] = payload; // Put target payload to the first.
    if (elements[i] !== payload) newLine[index++] = elements[i];
  }
  return newLine.join(" ");
}

function removeCN(sdpLines, mLineIndex) {
  var mLineElements = sdpLines[mLineIndex].split(" ");
  // Scan from end for the convenience of removing an item.
  for (var i = sdpLines.length - 1; i >= 0; i--) {
    var payload = extractSdp(sdpLines[i], /a=rtpmap:(\d+) CN\/\d+/i);
    if (payload) {
      var cnPos = mLineElements.indexOf(payload);
      if (cnPos !== -1) {
        // Remove CN payload from m line.
        mLineElements.splice(cnPos, 1);
      }
      // Remove CN line in sdp
      sdpLines.splice(i, 1);
    }
  }

  sdpLines[mLineIndex] = mLineElements.join(" ");
  return sdpLines;
}
