//@ts-check
/**
 * Components for rendering video streams. Either from the url to a static
 * (prerecorded) file or from browser MediaStreamTrack instances (provided as a
 * pair, one for video and one for audio)
 */
import * as React from "react";

/**
 * Given a url to a video file, render a <video> element that uses that file as
 * its source.
 *
 * n.b. The volume belongs to the video *element*, not on its audio
 * `MediaStreamTrack` — which this component yields to the caller via its
 * onTracksLoaded callback. The `MediaStreamTrack` doesn't have volume.
 *
 * @example
 *
 * function() { return "foo" }
 *
 */
export function FromUrl({
  url,
  onTracksLoaded = __trackPair => {},
  volume = 1,
  ...props
}) {
  const [isVideoLoaded, setIsVideoLoaded] = React.useState(false);
  const videoElRef = React.useRef();
  useVolumeSetter(videoElRef, volume);

  React.useEffect(() => {
    if (!isVideoLoaded) return;
    if (!videoElRef.current) return;
    // @ts-ignore
    const mediaStream = videoElRef.current.captureStream();
    const [video] = mediaStream.getVideoTracks();
    const [audio] = mediaStream.getAudioTracks();
    onTracksLoaded(new TrackPair({ video, audio }));
  }, [isVideoLoaded]);

  // ?(myles) export video aspect ratio when loaded so parent can respect it?
  return (
    <>
      <video
        src={url}
        ref={videoElRef}
        onCanPlay={() => setIsVideoLoaded(true)}
        {...props}
      ></video>
    </>
  );
}

/**
 * Given a AVChannelService.TrackPair, render a <video> element that uses that
 * as its source
 */
export function FromTracks({ trackPair, volume, ...props }) {
  const videoElRef = React.useRef(null);
  useVolumeSetter(videoElRef, volume);

  // + test on mobile
  React.useEffect(() => {
    if (!trackPair.count) return;
    const videoEl = videoElRef.current;
    videoEl.srcObject = new MediaStream();
    if (trackPair.audio) videoEl.srcObject.addTrack(trackPair.audio);
    if (trackPair.video) videoEl.srcObject.addTrack(trackPair.video);
  }, [trackPair]);

  return <video autoPlay ref={videoElRef} {...props} />;
}

function useVolumeSetter(videoElRef, volume) {
  React.useEffect(() => {
    const el = videoElRef.current;
    if (el) el.volume = volume;
  }, [volume]);
}

/** Some methods intentionally return instances where both tracks are null */
export class TrackPair {
  /**
   * @type MediaStreamTrack?
   */ audio;
  /**
   * @type MediaStreamTrack?
   */ video;
  constructor(props = {}) {
    Object.assign(this, props);
  }
  /**
   * @type {number}
   */
  get count() {
    return +!!this.video + +!!this.audio;
  }
}
