//@ts-check
import * as React from "react";

/**
 * @typedef {HTMLVideoElement & { captureStream: () => MediaStream }} HTMLVideoElementWithCaptureStream
 * @prettierignore
 */

export function FromUrl({
  url,
  onTracksLoaded = (__videoTrack, __audioTrack) => {},
}) {
  /**
   * @type React.MutableRefObject<HTMLVideoElementWithCaptureStream>
   */
  const videoRef = React.useRef();
  const [isVideoLoaded, setIsVideoLoaded] = React.useState(false);

  React.useEffect(() => {
    const videoEl = videoRef.current;
    if (!videoEl) return;
    videoEl.volume = 0;
  }, []);

  React.useEffect(() => {
    if (!isVideoLoaded) return;
    const captureStream = videoRef.current.captureStream();
    const [audioTrack] = captureStream.getAudioTracks();
    const [videoTrack] = captureStream.getVideoTracks();
    onTracksLoaded(videoTrack, audioTrack);
  }, [isVideoLoaded]);

  // ? export video aspect ratio when loaded so parent can respect it?
  return (
    <>
      <video
        loop
        autoPlay
        controls
        style={{ width: 1920 / 4, height: 1080 / 4 }}
        ref={videoRef}
        onCanPlay={() => setIsVideoLoaded(true)}
      >
        <source src={url} />
      </video>
    </>
  );
}

export function FromTracks({ mediaTrackPair, styleStr }) {
  const vidContainerRef = React.useRef(null);

  // lets worry about testing this on mobile later ...
  React.useEffect(() => {
    const vidContainer = vidContainerRef.current;
    if (!vidContainer) {
      return;
    }
    if (vidContainer.querySelector("video")) {
      vidContainer.removeChild(vidContainer.querySelector("video"));
    }
    let videoEl;

    videoEl = document.createElement("video");
    videoEl.srcObject = new MediaStream();
    if (mediaTrackPair.audio) {
      videoEl.srcObject.addTrack(mediaTrackPair.audio);
    }
    if (mediaTrackPair.video) {
      videoEl.srcObject.addTrack(mediaTrackPair.video);
    }

    videoEl.setAttribute("preload", "auto");
    videoEl.setAttribute("autoPlay", "");
    videoEl.setAttribute("playsinline", "");
    videoEl.setAttribute("muted", "false");
    // videoEl.setAttribute("controls", true);
    videoEl.setAttribute("style", styleStr);
    vidContainer.appendChild(videoEl);
    videoEl.load();
  }, [mediaTrackPair.count]);

  return <div ref={vidContainerRef} />;
}
