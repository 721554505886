//@ts-check
import * as React from "react";
import { produce } from "immer";
import * as Utils from "~/Utils";
import { TrackPair, _Context, PubStatus, SubStatus } from "./AVChannelService";

/**
 * class
 */
export class AgoraBackendConfig {
  /**
   * @type {string}
   */
  appId = "";
  /**
   * @type {AgoraRTC_.IAgoraRTC}
   */
  sdkModule = null;
  /**
   * @param {{ appId: string; sdkModule: AgoraRTC_.IAgoraRTC }} props
   */
  constructor(props) {
    Object.assign(this, props);
  }
}

/**
 * @class
 */
export class AgoraConnectConfig {
  /**
   * @type {'vp8' | 'h264'}
   */
  codec = "vp8";
  /**
   * @type {'rtc' | 'live'}
   */
  mode = "rtc";
  /**
   * @type {'host' | 'audience'| null}
   */
  role = null;
  /**
   * @param {{
   *   codec: 'vp8' | 'h264';
   *   mode: 'rtc' | 'live';
   *   role: 'host' | 'audience'| null;
   * }} props
   */
  constructor(props) {
    Object.assign(this, props);
  }
}

/**
 * @typedef ChannelSessionStateItem
 * @property {AVChannelService_.ConnStatus} connStatus
 * @property {{
 *   audio: AVChannelService_.TrackPubStatus;
 *   video: AVChannelService_.TrackPubStatus;
 * }} publishStatus
 * @property {Object.<
 *   string,
 *   {
 *     audio: AVChannelService_.TrackSubStatus;
 *     video: AVChannelService_.TrackSubStatus;
 *   }
 * >} subscriptionStatuses
 * @property {Object<string, {agoraUser: AgoraRTC_.IAgoraRTCRemoteUser, subTrackPair: TrackPair}>} entityInternalState,
 * @property {AgoraRTC_.IAgoraRTCClient} client
 * @property {Object<string, AgoraRTC_.ILocalTrack>} mediaStreamTrackToLocalTrack
 */
/**
 * @typedef {Object.<string, ChannelSessionStateItem>} StateType
 */
/**
 * @param {Object} props
 * @param {AgoraBackendConfig} props.config
 * @param {React.ReactElement} props.children
 * @returns {React.ReactElement}
 */
export function AgoraAVChannelService({ config, children }) {
  /**
   * @type StateType
   */
  const initialState = {};
  const [state, setState] = React.useState(initialState);

  /**
   * @callback stateTransformer
   * @param {StateType} draft
   * @returns {void}
   */
  /**
   * @function
   * @param {stateTransformer} f
   */
  const produceState = f => setState(produce(f));

  /**
   * @type {Object.<string, boolean>}
   */
  const disconnectingSessions = {};
  const currentlyDisconnectingRef = React.useRef(disconnectingSessions);

  /**
   * @type {AVChannelService_.API}
   */
  const avcs = {
    async connect(csk, { authToken }, backendSpecificConfig) {
      let session = state[csk.strKey];
      if (session) {
        // we can't be idempotent here because the second caller could be providing different backendParams
        // + use new log module
        console.warn(
          "AVChannelService#UnexpectedConnect",
          `${csk.strKey} already initialized`,
        );
        return; // otherwise session exists so return
      }
      if (!(backendSpecificConfig instanceof AgoraConnectConfig)) {
        throw new Error("Wrong config for connect");
      }
      const client = config.sdkModule.createClient({
        codec: backendSpecificConfig.codec,
        mode: backendSpecificConfig.mode,
      });
      if (backendSpecificConfig.role) {
        if (backendSpecificConfig.mode !== "live") {
          throw new Error("role is only supported in live mode");
        }
        await client.setClientRole(backendSpecificConfig.role);
      }
      produceState(draft => {
        draft[csk.strKey] = {
          connStatus: "connecting",
          publishStatus: { audio: "unattached", video: "unattached" },
          subscriptionStatuses: {},
          entityInternalState: {},
          client: client,
          mediaStreamTrackToLocalTrack: {},
        };
      });
      client.on("user-joined", async agoraUser => {
        produceState(draft => {
          draft[csk.strKey].subscriptionStatuses[agoraUser.uid] = {
            audio: "unattached",
            video: "unattached",
          };
          draft[csk.strKey].entityInternalState[agoraUser.uid] = {
            agoraUser,
            subTrackPair: new TrackPair({}),
          };
        });
      });
      client.on("user-left", async agoraUser => {
        produceState(draft => {
          console.assert(
            !!draft[csk.strKey].subscriptionStatuses[agoraUser.uid],
          );
          delete draft[csk.strKey].subscriptionStatuses[agoraUser.uid];
          delete draft[csk.strKey].entityInternalState[agoraUser.uid];
        });
      });
      client.on("user-published", async (agoraUser, mediaType) => {
        produceState(draft => {
          const previousStatus =
            draft[csk.strKey].subscriptionStatuses[agoraUser.uid];
          draft[csk.strKey].subscriptionStatuses[agoraUser.uid] = {
            audio: mediaType === "audio" ? "attachable" : previousStatus.audio,
            video: mediaType === "video" ? "attachable" : previousStatus.video,
          };
          draft[csk.strKey].entityInternalState[agoraUser.uid] = {
            ...draft[csk.strKey].entityInternalState[agoraUser.uid],
            agoraUser,
          };
        });
      });
      client.on("user-unpublished", (agoraUser, mediaType) => {
        produceState(draft => {
          const previousStatus =
            draft[csk.strKey].subscriptionStatuses[agoraUser.uid];
          const previousPair =
            draft[csk.strKey].entityInternalState[agoraUser.uid].subTrackPair;
          draft[csk.strKey].subscriptionStatuses[agoraUser.uid] = {
            audio: mediaType === "audio" ? "unattached" : previousStatus.audio,
            video: mediaType === "video" ? "unattached" : previousStatus.video,
          };
          draft[csk.strKey].entityInternalState[agoraUser.uid] = {
            agoraUser,
            subTrackPair: new TrackPair({
              audio: mediaType === "audio" ? null : previousPair.audio,
              video: mediaType === "video" ? null : previousPair.video,
            }),
          };
        });
      });

      try {
        await client.join(config.appId, csk.cid, authToken, csk.eid);
        produceState(draft => {
          draft[csk.strKey].connStatus = "connected";
        });
      } catch (e) {
        console.log(`Failed connecting for ${csk.strKey}`);
        produceState(draft => {
          delete draft[csk.strKey];
        });
        throw e;
      }
    },
    async disconnect(csk) {
      // + transition to a "disposing" state, then destroy after some timeout exceeded an no component tried to reconnect to the same session
      let session = state[csk.strKey];
      console.log(session);
      if (!session) return;
      // store disconnecting status in a ref and bail early if already disconnecting
      if (
        session.connStatus !== "connected" ||
        currentlyDisconnectingRef.current[csk.strKey]
      )
        return;
      currentlyDisconnectingRef.current[csk.strKey] = true;
      await state[csk.strKey].client.leave();
      delete currentlyDisconnectingRef.current[csk.strKey];
      produceState(draft => {
        delete draft[csk.strKey];
      });
    },
    connStatus(csk) {
      return state[csk.strKey] ? state[csk.strKey].connStatus : "unconnected";
    },
    async publish(csk, track) {
      let session = state[csk.strKey];
      if (!session || session.connStatus !== "connected") {
        throw new Error(
          `#InvalidState: Attempt to publish to a channel (${csk.cid}) not yet connected`,
        );
      }
      if (
        (track.kind === "audio"
          ? session.publishStatus.audio
          : session.publishStatus.video) !== "unattached"
      ) {
        console.warn(
          "DuplidatePublishing",
          `${track.kind} already published for ${csk.strKey}`,
        );
        return;
      }
      const agoraCustomTrack =
        track.kind === "audio"
          ? config.sdkModule.createCustomAudioTrack({ mediaStreamTrack: track })
          : config.sdkModule.createCustomVideoTrack({
              mediaStreamTrack: track,
            });
      produceState(draft => {
        draft[csk.strKey].publishStatus = {
          audio:
            track.kind === "audio"
              ? "attaching"
              : draft[csk.strKey].publishStatus.audio,
          video:
            track.kind === "video"
              ? "attaching"
              : draft[csk.strKey].publishStatus.video,
        };
        draft[csk.strKey].mediaStreamTrackToLocalTrack[track.id] =
          agoraCustomTrack;
      });
      try {
        await state[csk.strKey].client.publish(agoraCustomTrack);
        produceState(draft => {
          draft[csk.strKey].publishStatus = {
            audio:
              track.kind === "audio"
                ? "attached"
                : draft[csk.strKey].publishStatus.audio,
            video:
              track.kind === "video"
                ? "attached"
                : draft[csk.strKey].publishStatus.video,
          };
        });
      } catch (e) {
        produceState(draft => {
          draft[csk.strKey].publishStatus = {
            audio:
              track.kind === "audio"
                ? "unattached"
                : draft[csk.strKey].publishStatus.audio,
            video:
              track.kind === "video"
                ? "unattached"
                : draft[csk.strKey].publishStatus.video,
          };
        });
        throw e;
      }
    },
    async unpublish(csk, track) {
      let session = state[csk.strKey];
      if (
        track.kind === "audio" &&
        session.publishStatus.audio === "unattached"
      )
        return;
      if (
        track.kind === "video" &&
        session.publishStatus.video === "unattached"
      )
        return;
      await state[csk.strKey].client.unpublish(
        state[csk.strKey].mediaStreamTrackToLocalTrack[track.id],
      );
      produceState(draft => {
        console.log(`${csk.strKey} ${track.kind} ${track.kind === "video"}`);
        draft[csk.strKey].publishStatus = {
          audio:
            track.kind === "audio"
              ? "unattached"
              : draft[csk.strKey].publishStatus.audio,
          video:
            track.kind === "video"
              ? "unattached"
              : draft[csk.strKey].publishStatus.video,
        };
        delete draft[csk.strKey].mediaStreamTrackToLocalTrack[track.id];
      });
    },
    pubStatus(csk) {
      return state[csk.strKey]
        ? new PubStatus(state[csk.strKey].publishStatus)
        : new PubStatus({});
    },
    async subscribe(csk, eid, mediaType) {
      const subStatus =
        mediaType === "audio"
          ? state[csk.strKey].subscriptionStatuses[eid].audio
          : state[csk.strKey].subscriptionStatuses[eid].video;
      if (subStatus === "unattached") {
        throw new Error(
          `SubscriptionError: ${eid} doesn't publish ${mediaType} for ${csk.strKey}`,
        );
      }
      if (subStatus !== "attachable") return;
      if (
        (mediaType === "audio"
          ? state[csk.strKey].subscriptionStatuses[eid].audio
          : state[csk.strKey].subscriptionStatuses[eid].video) !== "attachable"
      )
        return;
      const agoraUser = state[csk.strKey].entityInternalState[eid].agoraUser;
      await state[csk.strKey].client.subscribe(agoraUser, mediaType);
      produceState(draft => {
        const previousPair =
          draft[csk.strKey].entityInternalState[eid].subTrackPair;
        draft[csk.strKey].subscriptionStatuses[eid] = {
          audio:
            mediaType === "audio"
              ? "attached"
              : draft[csk.strKey].subscriptionStatuses[eid].audio,
          video:
            mediaType === "video"
              ? "attached"
              : draft[csk.strKey].subscriptionStatuses[eid].video,
        };
        draft[csk.strKey].entityInternalState[eid].subTrackPair = new TrackPair(
          {
            audio:
              mediaType === "audio"
                ? agoraUser.audioTrack.getMediaStreamTrack()
                : previousPair.audio,
            video:
              mediaType === "video"
                ? agoraUser.videoTrack.getMediaStreamTrack()
                : previousPair.video,
          },
        );
      });
    },
    async unsubscribe(csk, eid, mediaType) {
      if (
        (mediaType === "audio"
          ? state[csk.strKey].subscriptionStatuses[eid].audio
          : state[csk.strKey].subscriptionStatuses[eid].video) !== "attached"
      )
        return;
      const agoraUser = state[csk.strKey].entityInternalState[eid].agoraUser;
      await state[csk.strKey].client.unsubscribe(agoraUser, mediaType);
      produceState(draft => {
        const previousPair =
          draft[csk.strKey].entityInternalState[eid].subTrackPair;
        draft[csk.strKey].subscriptionStatuses[eid] = {
          audio:
            mediaType === "audio"
              ? "attachable"
              : draft[csk.strKey].subscriptionStatuses[eid].audio,
          video:
            mediaType === "video"
              ? "attachable"
              : draft[csk.strKey].subscriptionStatuses[eid].video,
        };
        draft[csk.strKey].entityInternalState[eid].subTrackPair = new TrackPair(
          {
            audio: mediaType === "audio" ? null : previousPair.audio,
            video: mediaType === "video" ? null : previousPair.video,
          },
        );
      });
    },
    eids(csk) {
      return state[csk.strKey]
        ? Object.keys(state[csk.strKey].subscriptionStatuses)
        : [];
    },
    getSubTrackPair(csk, eid) {
      return state[csk.strKey] && state[csk.strKey].entityInternalState[eid]
        ? state[csk.strKey].entityInternalState[eid].subTrackPair
        : new TrackPair({});
    },
    subStatus(csk, eid) {
      return state[csk.strKey] && state[csk.strKey].subscriptionStatuses[eid]
        ? new SubStatus(state[csk.strKey].subscriptionStatuses[eid])
        : new SubStatus({});
    },
    csInt32Digest(csk) {
      if (!state[csk.strKey]) {
        return 0;
      }
      const serializableState = {
        connStatus: state[csk.strKey].connStatus,
        publishStatus: state[csk.strKey].publishStatus,
        subscriptionStatuses: state[csk.strKey].subscriptionStatuses,
      };
      return Utils.int32Digest(JSON.stringify(serializableState));
    },
  };

  return <_Context.Provider value={{ avcs }}>{children}</_Context.Provider>;
}
