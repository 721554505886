//@ts-check
import * as React from "react";
import { AgoraSDKService } from "~/AgoraSDKService";
import {
  AVChannelService,
  TrackPair,
  ChanSessionKey,
  useAVChannelService,
} from "~/AVChannelService";
import {
  AgoraBackendConfig,
  AgoraConnectConfig,
} from "./AgoraAVChannelService";
import { AppError, useDispatchToErrorBoundary } from "~/ErrorBoundary";
import * as Utils from "~/Utils";
import * as VideoRenderer from "./VideoFileRenderer";
import {
  SimpleStunBackendConfig,
  SimpleStunConnectConfig,
} from "./SimpleStunChannelService";

/**
 * @param {{ channelId: string, userId: string, role: "host" | "audience", token: string, backend : "agora" | "simple-stun" }} props
 * @returns {React.ReactElement}
 */
export default function SimpleStage({
  channelId,
  userId,
  role,
  token,
  backend,
}) {
  // @ts-ignore
  const appId = import.meta.env.CLIENT_EXPOSED_AGORA_APP_ID;
  if (typeof appId !== "string")
    throw AppError("ConfigError", "CLIENT_EXPOSED_AGORA_APP_ID must be set");
  if (backend === "agora") {
    return (
      <AgoraSDKService>
        {sdkModule =>
          sdkModule ? (
            <AVChannelService
              config={{
                backend: backend,
                backendConfig: new AgoraBackendConfig({
                  appId,
                  sdkModule,
                }),
              }}
            >
              <SimpleStageController
                cid={channelId}
                eid={userId}
                role={role}
                token={token}
                backend={backend}
              />
            </AVChannelService>
          ) : (
            // showing nothing is better than flashing a loading msg (for <300ms)
            <>{/* loading agora module ... */}</>
          )
        }
      </AgoraSDKService>
    );
  } else {
    return (
      <AVChannelService
        config={{
          backend: backend,
          backendConfig: new SimpleStunBackendConfig({
            wsURL: `${window.location.protocol === "https:" ? "wss" : "ws"}://${
              window.location.host
            }/ws/`,
          }),
        }}
      >
        <SimpleStageController
          cid={channelId}
          eid={userId}
          role={role}
          token={token}
          backend={backend}
        />
      </AVChannelService>
    );
  }
}

/**
 * @param {Object} props
 * @param {string} props.cid
 * @param {string} props.eid
 * @param {"host" | "audience"} props.role
 * @param {string} props.token
 * @param {"agora" | "simple-stun"} props.backend
 * @returns {React.ReactElement}
 */
function SimpleStageController({ cid, eid, role, token, backend }) {
  if (role === "host") {
    return (
      <SimpleStageHostController
        cid={cid}
        eid={eid}
        token={token}
        backend={backend}
      />
    );
  } else {
    return (
      <SimpleStageAudienceController
        cid={cid}
        eid={eid}
        token={token}
        backend={backend}
      />
    );
  }
}

/**
 * @param {Object} props
 * @param {string} props.cid
 * @param {string} props.eid
 * @param {string} props.token
 * @param {"agora" | "simple-stun"} props.backend
 * @returns {React.ReactElement}
 */
function SimpleStageHostController({ cid, eid, token, backend }) {
  const isMountedRef = React.useRef(true);
  /** @type {WebSocket} */
  const initSocket = null;
  const socketRef = React.useRef(initSocket);
  const keepAliveRef = React.useRef(null);
  const refreshTokenRef = React.useRef(null);
  const [shouldBroadcast, setShouldBroadcast] = React.useState(false);
  const [shouldRecord, setShouldRecord] = React.useState(false);
  const [grabbingCamera, setGrabbingCamera] = React.useState(false);
  const { avcs } = useAVChannelService();
  const dispatchToErrorBoundary = useDispatchToErrorBoundary();
  const [agoraTokens, setAgoraTokens] = React.useState([]);
  const [localMediaTrackPair, setLocalMediaTrackPair] = React.useState(
    new TrackPair({}),
  );
  const csi = new ChanSessionKey({ cid, eid });
  const connStatus = avcs.connStatus(csi); // channel session connection status
  const pubStatus = avcs.pubStatus(csi); // channel session publish status

  /** @type {React.MutableRefObject<TrackPair>} */
  const localMediaTrackPairRef = React.useRef(null);
  localMediaTrackPairRef.current = localMediaTrackPair;
  /** @type {React.MutableRefObject<boolean>} */
  const grabbingCameraRef = React.useRef(null);
  grabbingCameraRef.current = grabbingCamera;
  /** @type {React.MutableRefObject<import("./AVChannelService_").ConnStatus>} */
  const connStatusRef = React.useRef(null);
  connStatusRef.current = connStatus;
  /** @type {React.MutableRefObject<AVChannelService_.API>} */
  const avcsRef = React.useRef(null);
  avcsRef.current = avcs;
  const stopBroadcast = async shouldBroadcast => {
    setShouldBroadcast(shouldBroadcast);
    if (!shouldBroadcast) {
      if (localMediaTrackPairRef.current.video) {
        localMediaTrackPairRef.current.video.stop();
        setLocalMediaTrackPair(new TrackPair({}));
      }
      if (grabbingCameraRef.current) {
        setGrabbingCamera(false);
      }
      if (connStatusRef.current === "connected") {
        await avcsRef.current.disconnect(csi);
      }
    }
  };
  const shouldRecordRef = React.useRef(null);
  shouldRecordRef.current = shouldRecord;
  /** @type {React.MutableRefObject<MediaRecorder>} */
  const mediaRecorderRef = React.useRef(null);
  /** @type {React.MutableRefObject<Blob[]>} */
  const chunksRef = React.useRef(null);
  const handleRecording = async newValue => {
    if (newValue === shouldRecordRef.current) {
      return;
    }
    setShouldRecord(newValue);
    if (newValue) {
      const stream = new MediaStream();
      stream.addTrack(localMediaTrackPairRef.current.video);
      mediaRecorderRef.current = new MediaRecorder(stream, {
        mimeType: "video/webm",
      });
      chunksRef.current = [];
      mediaRecorderRef.current.ondataavailable = function (e) {
        chunksRef.current.push(e.data);
      };
      mediaRecorderRef.current.onstop = async function (__e) {
        const blob = new Blob(chunksRef.current, { type: "video/webm" });
        const formData = new FormData();
        formData.append("video", blob);
        formData.append("token", token);
        await fetch("/api/save-recording", { method: "POST", body: formData });
        mediaRecorderRef.current = null;
        chunksRef.current = null;
      };
      mediaRecorderRef.current.start();
    } else {
      mediaRecorderRef.current.stop();
    }
  };

  const [keysDown, setKeysDown] = React.useState({});
  const [camSelectorVisible, setCamSelectorVisible] = React.useState(false);
  const [camList, setCamList] = React.useState(null);

  React.useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: false })
      .then(s => s.getVideoTracks()[0].stop());
    () => {
      isMountedRef.current = false;
    };
  }, []);

  const handleKeyDown = React.useCallback(e => {
    if (e.target.tagName !== "BODY") {
      return;
    }

    setKeysDown(keys => ({ ...keys, [e.keyCode]: true }));
  }, []);

  const handleKeyUp = React.useCallback(e => {
    if (e.target.tagName !== "BODY") {
      return;
    }

    setKeysDown(keys => ({ ...keys, [e.keyCode]: false }));
  }, []);

  React.useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  React.useEffect(() => {
    // CTRL, SHIFT, V
    if (keysDown[17] && keysDown[16] && keysDown[86]) {
      setCamSelectorVisible(v => !v);
    }
  }, [keysDown]);

  React.useEffect(() => {
    Utils.callAndCatch(async () => {
      if (!camSelectorVisible) {
        return;
      }
      const devices = await navigator.mediaDevices.enumerateDevices();
      setCamList(devices.filter(d => d.kind === "videoinput"));
    }, dispatchToErrorBoundary);
  }, [camSelectorVisible]);

  React.useEffect(() => {
    Utils.callAndCatch(async () => {
      const tokens = await getAgoraTokens([csi], token);
      if (isMountedRef.current) setAgoraTokens(tokens);
    }, dispatchToErrorBoundary);
  }, []);

  React.useEffect(() => {
    socketRef.current = new WebSocket(
      `${window.location.protocol === "https:" ? "wss" : "ws"}://${
        window.location.host
      }/ws/`,
    );
    socketRef.current.onmessage = async function (message) {
      const json = JSON.parse(message.data);
      const data = json.data;
      if (json.eventName === "get-broadcast-response") {
        await stopBroadcast(data.shouldBroadcast);
      } else if (json.eventName === "get-recording-response") {
        await handleRecording(data.shouldRecord);
      } else {
        console.warn(`Unknown message: ${message}`);
      }
    };
    keepAliveRef.current = setInterval(() => {
      socketRef.current.send(
        JSON.stringify({ eventName: "get-broadcast", data: { token } }),
      );
      socketRef.current.send(
        JSON.stringify({ eventName: "get-recording", data: { token } }),
      );
    }, 5000);
    refreshTokenRef.current = setInterval(async () => {
      const tokens = await getAgoraTokens([csi], token);
      if (isMountedRef.current) setAgoraTokens(tokens);
    }, 6 * 3600000);
    return () => {
      if (!isMountedRef.current) {
        socketRef.current?.close();
        if (keepAliveRef.current) {
          clearInterval(keepAliveRef.current);
        }
        if (refreshTokenRef.current) {
          clearInterval(refreshTokenRef.current);
        }
      }
    };
  }, []);

  React.useEffect(() => {
    Utils.callAndCatch(async () => {
      if (!shouldBroadcast || grabbingCamera) {
        return;
      }
      setGrabbingCamera(true);
      const constraints = {
        audio: false,
        video: {
          width: 1920,
          height: 1080,
        },
      };
      if (localStorage.getItem("cameraId")) {
        constraints.video = {
          deviceId: {
            exact: localStorage.getItem("cameraId"),
          },
          ...constraints.video,
        };
      }
      await navigator.mediaDevices
        .getUserMedia(constraints)
        .then(function (stream) {
          if (!isMountedRef.current) {
            stream.getVideoTracks()[0].stop();
          }
          setLocalMediaTrackPair(
            new TrackPair({
              audio: null,
              video: stream.getVideoTracks()[0],
            }),
          );
        })
        .catch(function (__e) {
          setGrabbingCamera(false);
        });
    }, dispatchToErrorBoundary);
  });

  React.useEffect(() => {
    Utils.callAndCatch(async () => {
      if (!shouldBroadcast) return;
      if (!agoraTokens.length) return;
      if (!localMediaTrackPair.video) return;
      if (connStatus === "unconnected") {
        await avcs
          .connect(
            csi,
            {
              authToken: backend === "agora" ? agoraTokens[0] : token,
            },
            backend === "agora"
              ? new AgoraConnectConfig({
                  codec: "vp8",
                  mode: "live",
                  role: "host",
                })
              : new SimpleStunConnectConfig({ dummy: "hey" }),
          )
          .catch(dispatchToErrorBoundary);
      }
    }, dispatchToErrorBoundary);
    return () => {
      if (!isMountedRef.current) {
        avcs.disconnect(csi);
      }
    };
  }, [shouldBroadcast, agoraTokens, connStatus, localMediaTrackPair]);

  React.useEffect(() => {
    Utils.callAndCatch(async () => {
      if (connStatus !== "connected") return;
      if (pubStatus.video === "unattached" && localMediaTrackPair.video) {
        await avcs.publish(csi, localMediaTrackPair.video);
      }
    }, dispatchToErrorBoundary);
  }, [connStatus, pubStatus, localMediaTrackPair]);

  return React.useMemo(() => {
    return (
      <SimpleStageHostPresenter
        localMediaTrackPair={localMediaTrackPair}
        camSelectorVisible={camSelectorVisible}
        cams={camList}
      />
    );
  }, [localMediaTrackPair, camSelectorVisible, camList]);
}

/**
 * @param {Object} props
 * @param {TrackPair} props.localMediaTrackPair
 * @param {boolean} props.camSelectorVisible
 * @param {MediaDeviceInfo[]} props.cams
 * @returns {React.ReactElement}
 */
function SimpleStageHostPresenter({
  localMediaTrackPair,
  camSelectorVisible,
  cams,
}) {
  const [selectedCam, setSelectedCam] = React.useState(
    localStorage.getItem("cameraId"),
  );
  return (
    <>
      {localMediaTrackPair.video ? (
        <VideoRenderer.FromTracks
          mediaTrackPair={localMediaTrackPair}
          styleStr={"width: 1280px; height: 720px;"}
        />
      ) : (
        <>No video published</>
      )}
      {camSelectorVisible &&
        cams &&
        (!localMediaTrackPair.video ? (
          <div>
            <div>
              <select onChange={e => setSelectedCam(e.target.value)}>
                {selectedCam === null && (
                  <option value="" selected disabled hidden>
                    Select a Camera
                  </option>
                )}
                {cams.map(cam => {
                  return (
                    <option
                      key={cam.deviceId}
                      value={cam.deviceId}
                      selected={cam.deviceId == selectedCam}
                    >
                      {cam.label}
                    </option>
                  );
                })}
              </select>
            </div>
            <div>
              <button
                onClick={() => {
                  if (selectedCam) {
                    localStorage.setItem("cameraId", selectedCam);
                  }
                }}
                disabled={selectedCam === null}
              >
                Confirm
              </button>
            </div>
          </div>
        ) : (
          <>Cannot change camera selection while publishing</>
        ))}
    </>
  );
}

/**
 * @param {Object} props
 * @param {string} props.cid
 * @param {string} props.eid
 * @param {string} props.token
 * @param {"agora" | "simple-stun"} props.backend
 * @returns {React.ReactElement}
 */
function SimpleStageAudienceController({ cid, eid, token, backend }) {
  const isMountedRef = React.useRef(true);
  /** @type {WebSocket} */
  const initSocket = null;
  const socketRef = React.useRef(initSocket);
  const keepAliveRef = React.useRef(null);
  const [shouldBroadcast, setShouldBroadcast] = React.useState(false);
  const [shouldRecord, setShouldRecord] = React.useState(false);
  const { avcs } = useAVChannelService();
  const dispatchToErrorBoundary = useDispatchToErrorBoundary();
  const [agoraTokens, setAgoraTokens] = React.useState([]);

  const csi = new ChanSessionKey({ cid, eid });

  const connStatus = avcs.connStatus(csi); // channel session connection status
  const eids = avcs.eids(csi);

  React.useEffect(() => {
    () => {
      isMountedRef.current = false;
    };
  }, []);

  React.useEffect(() => {
    socketRef.current = new WebSocket(
      `${window.location.protocol === "https:" ? "wss" : "ws"}://${
        window.location.host
      }/ws/`,
    );
    socketRef.current.onmessage = function (message) {
      const json = JSON.parse(message.data);
      const data = json.data;
      if (json.eventName === "get-broadcast-response") {
        setShouldBroadcast(data.shouldBroadcast);
      } else if (json.eventName === "get-recording-response") {
        setShouldRecord(data.shouldRecord);
      } else {
        console.warn(`Unknown message: ${message}`);
      }
    };
    keepAliveRef.current = setInterval(() => {
      socketRef.current.send(
        JSON.stringify({ eventName: "get-broadcast", data: { token } }),
      );
      socketRef.current.send(
        JSON.stringify({ eventName: "get-recording", data: { token } }),
      );
    }, 5000);
    return () => {
      if (!isMountedRef.current) {
        socketRef.current?.close();
        if (keepAliveRef.current) {
          clearInterval(keepAliveRef.current);
        }
      }
    };
  }, []);

  React.useEffect(() => {
    Utils.callAndCatch(async () => {
      const tokens = await getAgoraTokens([csi], token);
      if (isMountedRef.current) setAgoraTokens(tokens);
    }, dispatchToErrorBoundary);
  }, []);

  React.useEffect(() => {
    Utils.callAndCatch(async () => {
      if (!agoraTokens.length) return;
      if (connStatus === "unconnected") {
        await avcs
          .connect(
            csi,
            {
              authToken: backend === "agora" ? agoraTokens[0] : token,
            },
            backend === "agora"
              ? new AgoraConnectConfig({
                  codec: "vp8",
                  mode: "live",
                  role: "audience",
                })
              : new SimpleStunConnectConfig({ dummy: "hey" }),
          )
          .catch(dispatchToErrorBoundary);
      }
    }, dispatchToErrorBoundary);
    return () => {
      if (!isMountedRef.current) {
        avcs.disconnect(csi);
      }
    };
  });

  React.useEffect(() => {
    Utils.callAndCatch(async () => {
      await Promise.all(
        // + flatmap and no await
        eids.map(async eid => {
          const subStatus = avcs.subStatus(csi, eid);
          if (subStatus.audio === "attachable") {
            await avcs.subscribe(csi, eid, "audio");
          }
          if (subStatus.video === "attachable") {
            await avcs.subscribe(csi, eid, "video");
          }
        }),
      );
    }, dispatchToErrorBoundary);
  }, [avcs.csInt32Digest(csi)]);

  const toggleBroadcastHandler = React.useCallback(() => {
    socketRef.current.send(
      JSON.stringify({
        eventName: "set-broadcast",
        data: { shouldBroadcast: !shouldBroadcast, token },
      }),
    );
    setShouldBroadcast(!shouldBroadcast);
  }, [shouldBroadcast]);

  const toggleRecordHandler = React.useCallback(() => {
    socketRef.current.send(
      JSON.stringify({
        eventName: "set-recording",
        data: { shouldRecord: !shouldRecord, token },
      }),
    );
    setShouldRecord(!shouldRecord);
  }, [shouldRecord]);

  return React.useMemo(() => {
    return (
      <SimpleStageAudiencePresenter
        avcs={avcs}
        csi={csi}
        connStatus={connStatus}
        remoteEids={eids}
        shouldBroadcast={shouldBroadcast}
        toggleBroadcastHandler={toggleBroadcastHandler}
        shouldRecord={shouldRecord}
        toggleRecordHandler={toggleRecordHandler}
      />
    );
  }, [
    avcs.csInt32Digest(csi),
    toggleBroadcastHandler,
    shouldBroadcast,
    shouldRecord,
  ]);
}

/**
 * @param {Object} props
 * @param {AVChannelService_.API} props.avcs
 * @param {ChanSessionKey} props.csi
 * @param {AVChannelService_.ConnStatus} props.connStatus
 * @param {string[]} props.remoteEids
 * @param {boolean} props.shouldBroadcast
 * @param {() => void} props.toggleBroadcastHandler
 * @param {boolean} props.shouldRecord
 * @param {() => void} props.toggleRecordHandler
 * @returns {React.ReactElement}
 */
function SimpleStageAudiencePresenter({
  avcs,
  csi,
  connStatus,
  remoteEids,
  shouldBroadcast,
  toggleBroadcastHandler,
  shouldRecord,
  toggleRecordHandler,
}) {
  switch (connStatus) {
    case "unconnected":
    case "connecting":
    case "reconnecting":
      return <div>Connecting to channel</div>;
    case "connected":
      return (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              flexWrap: "wrap",
            }}
          >
            {!remoteEids ? null : renderStreams(avcs, csi, remoteEids)}
          </div>
          <button onClick={toggleBroadcastHandler}>
            {shouldBroadcast ? "Disable" : "Enable"}
          </button>
          <button onClick={toggleRecordHandler}>
            {shouldRecord ? "Stop recording" : "Start recording"}
          </button>
        </div>
      );
  }
}

/**
 * @param {AVChannelService_.API} avcs
 * @param {string[]} remoteEids
 * @returns
 */
function renderStreams(avcs, csi, remoteEids) {
  const streams = remoteEids.map(remoteEid => {
    const subStatus = avcs.subStatus(csi, remoteEid);
    const trackPair = avcs.getSubTrackPair(csi, remoteEid);
    switch (subStatus.video) {
      case "attached":
        return (
          <div key={remoteEid}>
            {subStatus.video === "attached" ? (
              <VideoRenderer.FromTracks
                key={`${remoteEid}-player`}
                mediaTrackPair={trackPair}
                styleStr={"width: 1280px; height: 720px;"}
              />
            ) : (
              <div
                style={{
                  width: "1280px",
                  height: "720px",
                  backgroundColor: "black",
                }}
              ></div>
            )}
            <div style={{ textAlign: "center" }}>{remoteEid}</div>
          </div>
        );
      case "attachable":
      case "attaching":
        return <div key={remoteEid}>Subscribing to {remoteEid}</div>;
      case "unattached":
        return <div key={remoteEid}>{remoteEid} doesn't publish</div>;
    }
  });
  streams.sort((a, b) => a.key.toString().localeCompare(b.key.toString()));
  return streams;
}

/**
 * @param {ChanSessionKey[]} agoraTokenParams
 * @param {string} token
 * @returns {Promise<string[]>}
 */
async function getAgoraTokens(agoraTokenParams, token) {
  const endpoint = "/api/getAgoraTokens";
  const res = await fetch(Utils.urlString({ path: endpoint }), {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({
      params: agoraTokenParams.map(({ cid, eid }) => ({
        cid,
        eid,
      })),
      token,
    }),
  });
  if (res.status !== 200)
    throw AppError(
      "getAgoraTokensRequestFailed",
      `request to ${endpoint} failed with ${res.status}`,
    );
  const tokens = await res.json();
  return tokens;
}
